.notifications-page-container {
    max-width: 1900px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 62px;
  }
  
  .notifications-page-container h2 {
    color: #003e80;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .notifications-page-list {
    list-style-type: none;
    padding: 0;
  }
  
  .notification-page-item {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
    
  }
  
  .notification-page-item h3 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .notification-page-item p {
    margin: 5px 0;
  }
  
  .notification-page-item.low-stock-threshold {
    border-left: 5px solid #ffc400;
  }

  
  
  .notification-page-item.expired-date {
    border-left: 5px solid #715656;
  }
  
  .notification-page-item.not-available {
    border-left: 5px solid #ff0000;
  }
  
  .dismiss-btn {
    background-color: #cc4d27;
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;

  }
  
  .dismiss-btn:hover {
    background-color: #a13d1e;
  }

  .postpone-btn{
    background-color: #006BB8;
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 20px;
  }

  .postpone-btn:hover {
    background-color: #03416d
  }

   .alert-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    border:none;
    border-top: 6px solid #0073ff !important;


  }

  
  .ok-btn {
    padding: 10px 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  
  
  .ok-btn {
    background-color: #0485e1;
    color: white;
    
  
  }

  .ok-btn:hover {
    background-color: #03416d;
  }

  .welcome-notification {
    background-color: #e6f7ff;
    border-left: 4px solid #1890ff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  }
  
  .welcome-notification h3 {
    color: #1890ff;
    margin-bottom: 10px;
  }
  
  .welcome-content {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .welcome-date {
    font-size: 14px;
    color: #8c8c8c;
    margin-top: 10px;
  }