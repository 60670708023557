.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #c3d3dc;
}

.auth-card {  
  background-color: #e9f4fb;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.354);
  padding: 40px;
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.h2 {
  margin-bottom: 40px;
  margin-top: 10px;
  background: linear-gradient(to bottom, #0080db, #00005f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 290%;
  font-weight: 200;
}

hr {
  width: 100%;
  border: 1px solid #00005f;
  margin-top: 10px;
  margin-bottom: 30px;
}

.error-message {
  color: rgb(218, 0, 0);
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}



label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #030303;
}

input{
  background-color: #fff ;
}

input, select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease;

}

input:focus, select:focus {
  border-color: #00005f;
  outline: none;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-container input {
  padding-right: 40px;
}

.password-toggle-text {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #0494d7;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 40px;
  transition: background-color 0.3s ease;
  margin-right: -5px;
  background-color: #ffffff;
}

.password-toggle-text:hover {
  color: rgb(1, 38, 56);
}

.auth-button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 4px;
  background-color: #0070ac;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.auth-button:hover {
  background-color: #00567d;
}

.store-info-auth {
  border-top: 1px solid #00005f;
  margin-top: 20px;
  padding-top: 20px;
  text-decoration: none;
}

.store-info-auth h3 {
  margin-bottom: 5px;
  color: #00005f;

}

.save-status-message {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
}

.save-status-message.saving {
  background-color: #FFF9C4;
  color: #FBC02D;
}

.save-status-message.success {
  background-color: #C8E6C9;
  color: #43A047;
}

.save-status-message.error {
  background-color: #FFCDD2;
  color: #E53935;
}

.error-message {
  color: #E53935;
  background-color: #FFCDD2;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  text-align: center;
}
