  @media (max-width: 1200px) {
    .storesecplan-container {
      background: white;
      border-radius: 12px;
      padding: 2rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      max-width: 1200px !important;
      margin-top: 25px;
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 25px;

    }
    }
    @media (min-width: 1201px) {
    .storesecplan-container {
      background: white;
      border-radius: 12px;
      padding: 2.5rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      max-width: 1200px !important;
      margin-left: 325px;
      margin-top: 25px;
      margin-bottom: 25px;
     
    }
    }
  
  .storesecplan-tabs {
    display: flex;
    justify-content: center;
  }
  
  .tab-button {
    padding: 8px 0;
    width: 50%;
    max-width: 190px;
    border: none;
    background-color: #08295f;
    font-weight: 100;
    cursor: pointer;
    color: rgb(255, 255, 255);

  }
  
  .tab-button.active {
    background-color: #003893;
    color: white;
  }
  
  .storesecplan-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .security-container {
    height: 100%;
  }
  
  .cameras-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    overflow-y: auto;
  }
  
  .camera-box {
    position: relative;
    aspect-ratio: 4/3;
    border: 1px solid #000;
    background-color: #fff;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
  .camera-label {
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
  }
  
  .add-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .add-icon {
    font-size: 36px;
    font-weight: bold;
  }
  
  .floorplan-container {
    height: 100%;
    position: relative;
  }
  
  .upload-floor-plan-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #003c97;
    color: white;
    border: none;
    padding: 8px 18px;
    cursor: pointer;
    font-weight: 100;
    z-index: 10;
  }
  
  .upload-floor-plan-btn:hover {
    background-color: #000c1e;

  }

  @media (max-width: 1200px) {
    .floor-box {
        position: relative;
        height: 550px !important;
        border: 2px solid #000;
        background-color: #fff;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        max-width: 850px;
        margin-right: 25px;
        margin-left: 25px;

    }
    }
    @media (min-width: 1201px) {
    .floor-box {
        position: relative;
        width: 100%;
        height: 550px !important;
        border: 2px solid #000;
        background-color: #fff;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        max-width: 1100px;
        margin-right: 25px;
        margin-left: 25px;

    }
    }


    @media (max-width: 1200px) {
        .floors-grid {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;
        padding-top: 40px;
        margin-right: 25px;
        margin-left: 25px;
        max-width: 850px;

        }
        }
        @media (min-width: 1201px) {
        .floors-grid {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;
        padding-top: 40px;
        align-items: center;
        justify-content: center;
        max-width: 1300px;
        }
        }
  
  .floor-label {
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .cameras-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .cameras-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .cameras-grid {
      grid-template-columns: 1fr;
    }
  }


  