.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9fafb;
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
}

.loading-animation {
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
}

.loading-bar {
  width: 200px;
  height: 6px;
  background-color: #e5e7eb;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;
}

.loading-bar::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #3b82f6;
  background-image: linear-gradient(90deg, #3b82f6 0%, #60a5fa 50%, #3b82f6 100%);
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
}

/* @keyframes shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
} */

.loading-text {
  font-size: 22px;
  color: #1f2937;
  margin-bottom: 10px;
  font-weight: 600;
}

.loading-percentage {
  font-size: 16px;
  color: #6b7280;
  font-weight: 500;
}

.simple-load-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dot {
  width: 16px;
  height: 16px;
  background-color: #3b82f6; /* Blue color for the dots */
  border-radius: 50%; /* Make dots circular */
  margin: 0 8px; /* Space between dots */
}

@media (prefers-color-scheme: dark) {
  .loading-container, .simple-load-container {
    background-color: #111827;
  }

  .loading-text {
    color: #f3f4f6;
  }

  .loading-percentage {
    color: #9ca3af;
  }

  .loading-bar {
    background-color: #374151;
  }

  .dot {
    background-color: #60a5fa; /* Slightly lighter blue for dark mode */
  }
}
