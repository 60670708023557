/* Profile Page Main Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  background-color: #f7fafc;
  color: #1a202c;
}

/* Profile Container Responsive Adjustments */
@media (max-width: 1200px) {
  .profile-container-main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
}

@media (min-width: 1201px) {
  .profile-container-main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-left: 305px;
  }
}

.profile-container {
  border-radius: 10px;
  margin: 30px auto;
  padding: 30px;
  max-width: 1600px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 31, 71, 0.1);
}

/* Header Styles */
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e8f0;
}

.profile-title {
  font-size: 24px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.settings-button {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.settings-button:hover {
  background: linear-gradient(120deg, #2b6cb0 0%, #2c5282 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Content Section */
.profile-content {
  display: flex;
  flex-direction: column;
}

/* Info Section */
.profile-info-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 16px 0;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #edf2f7;
  border-radius: 6px;
  margin-bottom: 16px;
}

.status-label {
  font-weight: 500;
  color: #4a5568;
  margin-right: 6px;
}

.status-value {
  font-weight: 600;
  color: #2c5282;
}

/* Save Status Indicator */
.save-status {
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
}

.save-status.saving {
  background-color: #FFF9C4;
  color: #FBC02D;
  border-left: 4px solid #FBC02D;
}

.save-status.success {
  background-color: rgba(76, 153, 175, 0.1);
  color: #1091cc;
  border-left: 4px solid #1091cc;
}

.save-status.error {
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
  border-left: 4px solid #f44336;
}

/* Stores List */
.stores-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.store-card {
  background-color: #f8fafc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: all 0.3s ease;
}

.store-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.store-card.expanded {
  background-color: #f8fafc;
}

.store-card-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.store-address {
  font-size: 16px;
  font-weight: 500;
  color: #2d3748;
  margin: 0;
}

.expand-button {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateX(10px);
}

.expand-button.visible {
  opacity: 1;
  transform: translateX(0);
}

.expand-button:hover {
  background: linear-gradient(120deg, #2b6cb0 0%, #2c5282 100%);
}

/* Store Details */
.store-details {
  padding: 0 20px 20px 20px;
  background-color: #ffffff;
  border-top: 1px solid #e2e8f0;
}

/* Store Info Grid */
.store-info-grid {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.info-row {
  
  display: flex;
  gap: 24px;
}

.info-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-label {
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.info-value {
  font-size: 15px;
  color: #2d3748;
}

/* Edit Form */
.edit-form {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.form-row {
  display: flex;
  gap: 24px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.form-group input {
  padding: 10px 12px;
  border: 1px solid #bcc7d3;
  border-radius: 6px;
  font-size: 14px;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.2s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
}

/* Button Group */
.button-group {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}

/* Buttons */
.primary-button {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.primary-button:hover {
  background: linear-gradient(120deg, #2b6cb0 0%, #2c5282 100%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.secondary-button {
  background-color: #edf2f7;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.secondary-button:hover {
  background-color: #e2e8f0;
  color: #2d3748;
}






.danger-button {
  background-color: white;
  color: #0d1d31;
  border: 1px solid rgb(121, 122, 124);
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.danger-button:hover {
  background-color: #de0404;
  color: #ffffff;
  border: 1px solid #de0404;
}

/* Profile Actions */
.profile-actions {
  display: flex;
  flex-direction: row; /* Changed from column to row */
  justify-content: space-between; /* To create space between the buttons */
  gap: 16px;
  margin-top: 24px; /* Increased from 12px for better spacing */
}

.add-store-button,
.logout-button {
  flex: 1; /* Make buttons take equal width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-store-button {
  display: inline-block;
  background: linear-gradient(120deg, #2c5282 0%, #1a365d 100%);
  color: white;
  text-decoration: none;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-store-button:hover {
  background: linear-gradient(120deg, #1a365d 0%, #19345d 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logout-button {
  background: linear-gradient(120deg, #c53030 0%, #9b2c2c 100%);
  color: white;
  border: none;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-button:hover {
  background: linear-gradient(120deg, #9b2c2c 0%, #822727 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Confirmation Dialog */
.confirmation-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 32, 44, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.confirmation-dialog {
  background-color: white;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.confirmation-message {
  font-size: 16px;
  color: #2d3748;
  margin-bottom: 24px;
  text-align: center;
}

.confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.confirm-button {
  background: linear-gradient(120deg, #c53030 0%, #9b2c2c 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.confirm-button:hover {
  background: linear-gradient(120deg, #9b2c2c 0%, #822727 100%);
}

.cancel-button {
  background-color: #edf2f7;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.cancel-button:hover {
  background-color: #e2e8f0;
  color: #2d3748;
}

/* Error and No Data States */
.error-message {
  color: #E53935;
  background-color: #FFCDD2;
  padding: 16px;
  border-radius: 8px;
  margin: 30px auto;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-data {
  color: #718096;
  background-color: #F7FAFC;
  padding: 30px;
  border-radius: 8px;
  margin: 30px auto;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  font-size: 16px;
}

/* ========== Profile Privacy Styles ========== */
.privacy-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.privacy-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.back-button-container {
  display: flex;
  align-items: center;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  padding: 6px 10px;
  border-radius: 0px;
  transition: color 0.2s ease;
}

.back-button:hover {
  color: #171717;
}

.back-arrow {
  font-size: 18px;
  margin-right: 5px;
}

.privacy-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.privacy-select,
.privacy-input {
  width: 100%;
  padding: 12px 14px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  font-size: 14px;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.2s ease;
}

.privacy-select:focus,
.privacy-input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
}

.temporary-code-container {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.input-wrapper {
  position: relative;
  flex: 1;
}

.privacy-input.with-timer {
  width: 100%;
  padding-right: 150px; /* Make room for the timer */
}

.timer-display {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 14px;
  pointer-events: none;
}

.generate-button-p {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(120deg, #006edc 0%, #006edc 100%);
  color: white;
  padding: 6px 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.generate-button-p:hover {
  background: linear-gradient(120deg, #004c98 0%, #004c98 100%);
}

.generate-button-p:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}

/* ========== Profile Settings Styles ========== */
.settings-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.settings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: #f8fafc;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 400;
  border: 1px solid #dbdbdb;
  transition: all 0.2s ease;
}

.settings-item:hover {
  background-color: #e0e1e4;
  border-color: #b0b8c0;
}

.settings-arrow {
  font-size: 20px;
  color: #666;
}

/* CountdownTimer component styles */
.countdown-timer {
  font-size: 14px;
  transition: color 0.3s ease;
}

.countdown-timer.urgent {
  color: #c20505;
  font-weight: 500;
}

.countdown-timer.normal {
  color: #666666;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .profile-container {
    padding: 20px;
    margin: 15px auto;
  }
  
  .form-row {
    flex-direction: column;
    gap: 16px;
  }
  
  .info-row {
    flex-direction: column;
    gap: 16px;
  }
  
  .button-group {
    flex-direction: column;
  }
  
  .privacy-form {
    padding: 0 15px;
  }
  
  .temporary-code-container {
    flex-direction: column;
  }
  
  .privacy-input.with-timer {
    padding-right: 90px;
  }
  
  .timer-display {
    right: 60px;
  }
}

@media (max-width: 480px) {
  .profile-actions {
    flex-direction: column; /* Stack vertically on very small screens */
  }
  .profile-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .settings-button {
    width: 100%;
  }
  
  .privacy-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .back-button-container {
    width: 100%;
  }
  
  .back-button {
    width: 100%;
    justify-content: flex-start;
  }
}