.messenger-container {
  display: flex;
  height: calc(100vh - 61px);
  background-color: #f8f9fa;
  margin-top: 60px;
}

.communication-sidebar {
  width: 265px;
  background-color: #05152f;
  height: calc(100% - 39px);

  padding: 20px 11px;
  color: #ffffff;
  overflow-y: auto;
}

.messenger-search-wrapper {
  margin-bottom: 24px;
}




.messenger-search-input-sidebar {
  width: 100%;
  padding: 12px 12px 12px 40px;
  background-color: #2d2f31;
  border: 1px solid #3a3d40;
  border-radius: 8px;
  font-size: 14px;
  color: #1a1919;
}

.messenger-search-input-sidebar:focus {
  outline: none;
  border-color: #4a4d50;
}

.communication-sidebar-navigation {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.communication-sidebar-link {
  display: flex;
  align-items: center;
  padding: 12px;
  text-decoration: none;
  color: #a1a5b7;
  border-radius: 8px;
  transition: all 0.2s;
}

.communication-sidebar-link:hover {
  background-color: #2d2f31;
  color: #ffffff;
}

.communication-sidebar-link.active {
  background-color: #2d2f31;
  color: #ffffff;
}

.communication-sidebar-icon {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}

.messenger-content {
  flex: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e9ecef;
}

.chats-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chats-header {
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.create-chat-button {
  padding: 8px 16px;
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.create-chat-button:hover {
  background-color: #0b5ed7;
}

.chats-list {
  flex: 1;
  overflow-y: auto;
  padding: 12px;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;

}

.chat-item:hover {
  background-color: #eaeaea;
}

.chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0648ab;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-right: 12px;
}

.chat-preview {
  flex: 1;
}

.chat-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.chat-participant {
  font-weight: 500;
  color: #212529;
  margin-bottom: 4px;
}

.chat-time {
  margin-left: auto;
  color: #666;
  font-size: 12px;
}

.new-chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.new-chat-header {
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
}

.messenger-search-user-container {
  padding: 20px;
  flex-direction: column;
  
}

.messenger-search-user-container-wrapper{
  position: relative;
  align-items: center;
  display: flex;
}


.messenger-search-container {
  position: relative;
  align-items: center;
}


.messenger-search-input-sidebar-wrapper {
  position: relative;
  margin-bottom: 20px;}


.messenger-search-user-input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 14px;
}

.messenger-search-user-input:focus {
  outline: none;
  border-color: #0d6efd;
}

.messenger-users-list {
  border: 1px solid #e9ecef;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 8px;

}

.messenger-user-item {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s;
  cursor: pointer;
}

.messenger-user-item:last-child {
  border-bottom: none;
}

.messenger-user-item:hover {
  background-color: #f8f9fa;
}


.messenger-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0648ab;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  flex-shrink: 0;
  margin-right: 10px;
}

.messenger-user-details {
  flex: 1;
}

.messenger-user-email {
  font-weight: 500;
  color: #212529;
}

.chat-window {
   display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.chat-header {
  padding: 10px;
  border-bottom: 1px solid #e9ecef;
  background-color: white;
}

.chat-recipient-info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 1px;
  margin-bottom: 1px;

}

.chat-recipient-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0648ab;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.chat-recipient-details {
  display: flex;
  flex-direction: column;
}

.chat-recipient-email {
  font-weight: 500;
  color: #212529;
}


.messages-outer-container {
  position: relative;
  height: calc(100vh - 160px); /* Adjust based on your header/input heights */
  overflow: hidden;
  display: flex;
  flex-direction: column;  /* Changed from column-reverse */
}




@media (max-width: 1200px) {
  .messages-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 1.5rem ;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column-reverse;
    
  }
  }
  @media (min-width: 1201px) {
  .messages-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 1.5rem 5rem;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column-reverse;
    align-items: center; 
  }
  }

  .messages-inner {
    display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  width: 100%; 
  max-width: 900px; 

  }

.message-wrapper {
   display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  animation: fadeIn 0.3s ease-in-out;

}

.message-wrapper.sent {
  flex-direction: row-reverse;
  justify-content: flex-start;
  
}

.message-wrapper.received {
  justify-content: flex-start;
}



.messenger-message-avatar {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  background-color: #0648ab !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px !important;
  margin: 0 8px 0 8px !important;
  flex-shrink: 0;
  font-weight: 100 !important;
}

.message-bubble-container {
  max-width: 70%;
  margin-bottom: 13px;
}


.messenger-message-avatar.sender {
  background-color: #28a745;
}


.message-bubble {
  background: white;
  border-radius: 16px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.message.sent {
  border-radius: 16px 16px 2px 16px;
  position: relative;
  margin: 0;
  max-width: 100%;
}

.message.received {
  border-radius: 16px 16px  16px 2px;
  position: relative;
  margin: 0;
  max-width: 100%;
}

.message.sent {
  background: linear-gradient(135deg, #0d3879, #0c2447);
}




.message.received {
  background: linear-gradient(135deg, #bac3cb, #aab3bc);
}


.message.received {
  align-self: flex-start;
  margin-left: 0;
}

.messenger-message-content {
  padding: 12px 16px;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 2px;

}


.message.sent .messenger-message-content {
  color: white;

}




.message.received .messenger-message-content {
  color: #080808;
}

.message.sent .message-footer {
  display: flex;
  align-items: flex-end;
  padding: 9px 9px;
  min-width: 78px;
}

.message.received .message-footer {
  display: flex;
  align-items: flex-end;
  padding: 9px 9px;
  min-width: 50px;

}


.message-meta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}


.message.sent .message-time {
  font-size: 11px;
  color: #6c757d;
  margin-left: 5px;
}

.message.received .message-time {
  font-size: 11px;
  color: rgb(50, 50, 50);
}

.message.sent .message-meta {
  color: rgba(255, 255, 255, 0.8);
}

.message.sent .message-time {
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
}


.message-status {
  display: flex;
  align-items: center;
  height: 14px;

}

.double-check {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 2px;
  margin-right: 4px;

}

.second-check {
  position: absolute;
  left: 4px;
}




.messenger-message-input-wrapper {
  position: sticky;
  bottom: 0;
  background-color: #f8f9fa;
  z-index: 1000;
  display: flex;
  justify-content: center;

}

.messenger-search-input-wrapper{
  height: 100px;
}

.messenger-message-input-form {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  max-width: 900px;
}


.messenger-message-input-container-wrapper {
  position: relative;
  display: flex;
  gap: 12px;
  padding: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  min-width: 100%;

}

.messenger-paperclip-icon {
  transform: translateY(-50%);
  color: #e0dfe5;
  padding: 13.5px 7px 13.5px 13.5px;
  border-radius: 50% 0 0 50% ;
  background-color: #012862;

}

.messenger-paperclip-icon-wrapper {
  position: absolute;
  top: 50%;
  padding-left: 4px;
}

.messenger-paperclip-icon:hover {
  color: #ffffff;
  background-color: #0a0c41;
  cursor: pointer;
}


.messenger-ordering-icon {
  transform: translateY(-50%);
  color: #fbfbfc;
  padding: 13.5px 8.5px 13.5px 8.5px;
  background-color: #0443a0;
  margin-left: 42.8px;
}



.messenger-ordering-icon:hover {
  color: #ffffff;
  background-color: #0350c3;
  cursor: pointer;

}




.messenger-message-input {
  padding: 16px 20px 15px 93px;
  border: 1px solid #c4cbd2;
  border-radius: 24px;
  font-size: 14.5px;
  background-color: #f8f9fa;
  transition: border-color 0.2s ease;
  width: 100%;
  margin-left: 5px;
}

.messenger-message-input:focus {
  outline: none;
  border-color: #c4cbd2 !important;
  background-color: white;
}


.messenger-send-button {
  padding: 12px;
  background-color: #012760;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-right: 5px;
}

.messenger-send-button:hover:not(:disabled) {
  background-color: #012151;
  transform: scale(1.03);
}

.messenger-send-button:disabled {
  background-color: #d9e0e7;
  cursor: not-allowed;
}

/* @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

@media (max-width: 768px) {
  .messenger-container {
    flex-direction: column;
  }

  .communication-sidebar {
    width: 100%;
    height: auto;
    padding: 12px;
  }

  .message {
    max-width: 85%;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* @keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message {
  animation: slideIn 0.3s ease-out;
}

.messenger-user-item, .chat-item {
  animation: slideIn 0.3s ease-out;
} */

.close-messenger-search-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #6c757d;
  transition: color 0.2s;
}

.close-messenger-search-button:hover {
  color: #212529;
}

.new-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-chat-btn {
  padding: 8px 16px;
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  white-space: nowrap;
}

.create-chat-btn:hover {
  background-color: #0956cb;
}

.no-store-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #6c757d;
  font-size: 16px;
}

.messenger-user-item {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s;
}

.messenger-user-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.messenger-search-input-sidebar-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.messenger-search-icon {
  position: absolute;
  padding-left: 12px;
  padding-right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  pointer-events: none;
  width: 16px;
  height: 16px;

}



.messenger-search-user-input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s;
}

.messenger-search-user-input:focus {
  outline: none;
  border-color: #0d6efd;

}

.no-results {
  text-align: center;
  padding: 20px;
  color: #6c757d;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-top: 12px;
}

.chat-unread-count {
  background-color: #075ede;
  color: white;
  border-radius: 12px;
  padding: 2px 3px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
  min-width: 20px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.messenger-image-container {
  max-width: 280px;
  margin: 5px 0;
  border-radius: 12px;
  overflow: hidden;
}

.messenger-image-message {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: contain;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s;
  min-width: 230px;
  max-width: 300px;

}

.messenger-image-message:hover {
  transform: scale(1.02);
}

.messenger-document-container {
  display: flex;
  align-items: center !important;
  padding: 8px 8px;
  background-color: rgb(167, 204, 235) !important;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  max-width: 230px;
  min-width: 230px;
  margin: 5px 0 5px 5px;
}


.message.received .messenger-document-container {
  display: flex;
  align-items: center !important;
  padding: 8px 8px;
  background-color: rgb(16, 52, 81) !important;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  max-width: 230px;
  min-width: 230px;
  margin: 5px 0 5px 5px;
}

.message.sent .messenger-document-container {
  display: flex;
  align-items: center !important;
  padding: 8px 8px;
  background-color: #a3b7cb !important;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  max-width: 230px;
  min-width: 230px;
  margin: 5px 0 5px 5px;
}

/* #bac3cb, #aab3bc */

.messenger-document-container:hover {
  background: rgba(0, 0, 0, 0.08);
}

.document-icon {
  width: 27px;
  height: auto;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.document-info {
  flex: 1;
  overflow: hidden;
}

.message.received .document-name {
  font-size: 14px;
  color: #f6f8fe;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message.received .document-size {
  font-size: 12px;
  color: #cbcbcb;
}


.message.sent .document-name {
  font-size: 14px;
  color: #010101;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message.sent .document-size {
  font-size: 12px;
  color: #3e3e3e;
}

.message.sent .messenger-document-container {
  background: rgba(255, 255, 255, 0.1);
}

.message.received .messenger-document-container {
  background: rgba(0, 0, 0, 0.05);
}

.messenger-create-order {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 8px;
}

.create-order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.items-container {
  max-height: 300px;
  overflow-y: auto;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 8px;
}

.add-item-form {
  display: grid;
  gap: 8px;
  margin-top: 16px;
}

.submit-order-button {
  width: 100%;
  padding: 8px;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 16px;
  cursor: pointer;
}

.submit-order-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}



.order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 8px;
}





.order-details {
    margin-bottom: 12px;
}

.order-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 0.9em;
    color: #444;
}

.order-items {
    border-top: 1px solid #dee2e6;
    padding-top: 8px;
}

.items-header {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.order-item {
    padding: 8px;
    background: white;
    border-radius: 4px;
    margin-bottom: 6px;
}

.item-name {
    font-weight: 500;
    margin-bottom: 4px;
}

.item-details {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 0.9em;
    color: #666;
}

.item-details span {
    background: #f1f3f5;
    padding: 2px 6px;
    border-radius: 4px;
}



.order-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.order-modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 16px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  padding: 16px;
  overflow-y: auto;
}

.modal-actions {
  padding: 16px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.order-items-table {
  width: 100%;
  border-collapse: collapse;
}

.order-items-table th,
.order-items-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #eee;
}





.order-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.order-main-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.order-number {
  font-weight: 600;
  color: #1a73e8;
  font-size: 1.1em;
}




.exchange-status {
  font-size: 0.85em;
  color: #6c757d;
}

.order-info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.info-item {
  display: flex;
  gap: 6px;
}

.info-label {
  color: #6c757d;
  font-size: 0.9em;
}

.info-value {
  font-weight: 500;
}

.items-header {
  font-weight: 500;
  margin-bottom: 8px;
  color: #495057;
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item-preview {
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  background: #e9ecef;
  border-radius: 4px;
}

.more-items {
  text-align: center;
  color: #6c757d;
  font-size: 0.9em;
  padding: 4px;
}



.messenger-order-message {
  border-radius: 8px;
  padding: 12px;
  max-width: 400px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.2s ease;
}

.messenger-order-message:hover {
  transform: translateY(-2px);
}


.order-status {
  font-size: 0.9em;
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: 500;
}

.exchange-status {
  font-weight: 500;
}

.exchange-status.receiver {
  color: #2196f3;
}

.exchange-status.sender {
  color: #4caf50;
}

.additional-items {
  color: #666;
  font-size: 0.9em;
  font-style: italic;
}

.order-actions {
  margin-top: 10px;
  display: flex;
  gap: 8px;
  padding-top: 8px;
  border-top: 1px solid #c0c0c0;
}






.message.sent .messenger-order-message {
  background: #e7eff7;
}

.message.received .messenger-order-message {
  background: #e7eff7;
}


.messenger-order-message {
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 12px !important ;
  max-width: 350px !important;
  min-width: 350px !important;
  margin: 8px 0;
  margin-left: 5px;

  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}


.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dee2e6;
}

.order-header-left {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.message.sent .order-number {
  font-weight: 400;
  color: #006eff;
}

.message.received .order-number {
  font-weight: 400;
  color: #1a73e8;
}



.order-status.pending {
  background: #ca9908;
  color: #ffffff;
}

.order-status.confirmed {
  background: #1a73e8;
  color: #ffffff;
}

.order-status.rejected {
  background: #ad0c0c;
  color: #ffffff;
}

.exchange-status {
  font-size: 0.85em;
  color: #6c757d;
  background: #e9ecef;
  padding: 2px 8px;
  border-radius: 12px;
  
}

.order-info {
  margin-bottom: 12px;
  
}

.info-row {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #495057;
  margin-bottom: 2px;
  
}

.order-items-preview {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 8px;
}

.items-header {
  font-weight: 500;
  color: #495057;
}

.items-list {
  font-size: 0.9em;
  margin-top: 1px !important;
}

.item-preview {
  color: #6c757d;
  margin-bottom: 2px;
}

.more-items {
  color: #1a73e8;
  font-size: 0.85em;
}


.messenger-view-button, .messenger-confirm-button, .messenger-reject-button {
  padding: 6px 12px !important;
  border: none;
  border-radius: 16px !important;
  font-size: 0.9em;
  cursor: pointer;
  flex: 1;
}




.messenger-view-button {
  background: #020e3a;
  color: #ffffff;
}


.messenger-confirm-button {
  background: #1a73e8;
  color: white;
}


.messenger-reject-button {
  background: #c50404;
  color: white;
}


.messenger-view-button:hover {
  background: #092d5c;
}


.messenger-confirm-button:hover {
  background: #093b7c;
}


.messenger-reject-button:hover {
  background: #820808;
}


