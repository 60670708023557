.BrokerAuth-auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #c3d3dc;
  }
  
  .BrokerAuth-auth-card {  
    background-color: #e9f4fb;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.354);
    padding: 40px;
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  .BrokerAuth-h2 {
    margin-bottom: 40px;
    margin-top: 10px;
    background: linear-gradient(to bottom, #0080db, #00005f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 290%;
    font-weight: 200;
  }
  
  .BrokerAuth-hr {
    width: 100%;
    border: 1px solid #00005f;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  
  .BrokerAuth-error-message {
    color: rgb(218, 0, 0);
    margin-bottom: 20px;
  }
  
  .BrokerAuth-form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .BrokerAuth-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #030303;
  }
  
  .BrokerAuth-input {
    background-color: #fff;
  }
  
  .BrokerAuth-input, .BrokerAuth-select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  .BrokerAuth-input:focus, .BrokerAuth-select:focus {
    border-color: #00005f;
    outline: none;
  }
  
  .BrokerAuth-password-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .BrokerAuth-password-container input {
    padding-right: 40px;
  }
  
  .BrokerAuth-password-toggle-text {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #0494d7;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 40px;
    transition: background-color 0.3s ease;
    margin-right: -5px;
    background-color: #ffffff;
  }
  
  .BrokerAuth-password-toggle-text:hover {
    color: rgb(1, 38, 56);
  }
  
  .BrokerAuth-auth-button {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 4px;
    background-color: #0070ac;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.3s ease;
  }
  
  .BrokerAuth-auth-button:hover {
    background-color: #00567d;
  }
  
  .BrokerAuth-store-info-auth {
    border-top: 1px solid #00005f;
    margin-top: 20px;
    padding-top: 20px;
    text-decoration: none;
  }
  
  .BrokerAuth-store-info-auth h3 {
    margin-bottom: 5px;
    color: #00005f;
  }
  
  .BrokerAuth-save-status-message {
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
  }
  
  .BrokerAuth-save-status-message.saving {
    background-color: #FFF9C4;
    color: #FBC02D;
  }
  
  .BrokerAuth-save-status-message.success {
    background-color: #C8E6C9;
    color: #43A047;
  }
  
  .BrokerAuth-save-status-message.error {
    background-color: #FFCDD2;
    color: #E53935;
  }
  
  .BrokerAuth-error-message {
    color: #E53935;
    background-color: #FFCDD2;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  /* Additional styles for employee registration */
  .BrokerAuth-auth-form {
    background-color: #e9f4fb;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.354);
    padding: 40px;
    max-width: 500px;
    width: 100%;
  }
  
  .BrokerAuth-broker-registration-form {
    max-width: 600px;
  }
  
  .BrokerAuth-step-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .BrokerAuth-step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
  }
  
  .BrokerAuth-step.active {
    background-color: #0070ac;
  }
  
  .BrokerAuth-step.completed {
    background-color: #43A047;
  }
  
  .BrokerAuth-step-line {
    flex-grow: 1;
    height: 2px;
    background-color: #ccc;
    margin: 0 10px;
  }
  
  .BrokerAuth-form-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .BrokerAuth-back-button,
  .BrokerAuth-next-button,
  .BrokerAuth-submit-button {
    padding: 12px 25px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .BrokerAuth-back-button {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .BrokerAuth-back-button:hover {
    background-color: #e0e0e0;
  }
  
  .BrokerAuth-next-button,
  .BrokerAuth-submit-button {
    background-color: #0070ac;
    color: #fff;
  }
  
  .BrokerAuth-next-button:hover,
  .BrokerAuth-submit-button:hover {
    background-color: #00567d;
  }
  
  .BrokerAuth-auth-link {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
  }
  
  .BrokerAuth-auth-link a {
    color: #0070ac;
    text-decoration: none;
  }
  
  .BrokerAuth-auth-link a:hover {
    text-decoration: underline;
  }
  
  .BrokerAuth-password-hint {
    font-size: 12px;
    margin-top: 5px;
  }
  
  .BrokerAuth-password-hint.invalid {
    color: #E53935;
  }