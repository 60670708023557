

/* Main container styling */
.registry-container {
  width: 100%;
  background-color: #f7fafc;
  color: #1a202c;
  margin-top: 60px;
  position: relative;
  padding-bottom: 40px;
}

.registry-header {
  background: linear-gradient(120deg, #1e3c72 0%, #2a5298 100%);
  color: white;
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 30px;
}

.registry-title {
  font-size: 28px;
  font-weight: 100;
  margin: 0;
  letter-spacing: 0.5px;
}

.registry-subtitle {
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0 0 0;
  color: rgba(255, 255, 255, 0.9);
}

.registry-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-title {
  font-size: 22px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
  padding-bottom: 10px;
  flex: 1;
}

.registry-header-hr {
  border: 1px solid #aeb8c5;

}

.registered-items-button {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.registered-items-button:hover {
  background: linear-gradient(120deg, #2b6cb0 0%, #2c5282 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.registry-inventory-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(26, 32, 44, 0.75);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}


.registry-inventory-modal {
  width: 90%;
  max-width: 1280px;
  height: 80%;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.registry-inventory-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px;
  background: linear-gradient(120deg, #193363 0%, #204179 100%);
  border-bottom: 1px solid #e2e8f0;
}


.registry-inventory-modal-content {
  flex: 1;
  padding: 24px 28px;
  overflow: auto; /* Change from overflow-y to overflow */
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px); /* Adjust based on your header height */
}

.registry-inventory-controls {
  margin-bottom: 5px;
  flex-shrink: 0; /* Prevent controls from shrinking */

}

.registry-inventory-search-filters {
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
}

.registry-inventory-search-input {
  flex: 2;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23718096' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
  padding-left: 40px;
}

.registry-inventory-search-input:focus {
  outline: none;
  border-color: #4299e1;
}

.filter-dropdown {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.filter-dropdown:focus {
  outline: none;
  border-color: #4299e1;
}

/* New filters and sort container styling */
.registry-inventory-filters-sort-container {
  display: flex;
  gap: 24px;
}

/* Filters section styling */
.registry-inventory-filters-section {
  display: flex;
  gap: 20px;
  flex: 2;
}

.filter-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-label {
  font-size: 12px;
  font-weight: 500;
  color: #4a5568;
}

/* Sort section styling */
.registry-inventory-sort-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.sort-label {
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.sort-controls {
  display: flex;
  gap: 12px;
  align-items: center;
}

.sort-dropdown {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}






.registry-inventory-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px;
  background: linear-gradient(120deg, #1e3c72 0%, #2a5298 100%);
  border-bottom: 1px solid #e2e8f0;
}

.registry-inventory-modal-header h2 {
  margin: 0;
  color: white;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.registry-inventory-modal-header h2::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7'%3E%3C/path%3E%3Cpath d='M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}


.registry-sort-order-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  background: linear-gradient(120deg, #1655ae 0%, #2060ae 100%);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 120px;
}

.registry-sort-order-button:hover {
  background: linear-gradient(120deg, #19498d 0%, #134078 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Inventory table container */
.registry-inventory-table-container {
  flex: 1;
  overflow: auto; /* Change from 'hidden' to 'auto' to allow scrolling */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 150px); /* Limit height to ensure vertical scrolling */
  margin-top: 10px;
}

.registry-inventory-table-wrapper {
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
}

.registry-inventory-table {
  width: 100%;
  min-width: 1000px; /* Set a minimum width to force horizontal scrolling on small screens */
  border-collapse: collapse;
  font-size: 14px;

}

.registry-inventory-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8fafc;
}

.registry-inventory-table th {
  position: sticky;
  top: 0;
  background-color: #e0e9f1;
  padding: 16px 20px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #e2e8f0;
  color: #4a5568;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.registry-inventory-table td {
  padding: 16px 20px;
  border-bottom: 1px solid #edf2f7da;
  color: #141a25;
  vertical-align: middle;
}

.registry-inventory-table tr {
  transition: background-color 0.2s ease;
}

.registry-inventory-table tr:hover {
  background-color: #f8fafc;
}
@media (max-height: 800px) {
  .registry-inventory-modal {
    height: 90%;
  }
  
  .registry-inventory-table-container {
    max-height: calc(100% - 130px);
  }
}

@media (max-width: 768px) {
  .registry-inventory-modal-content {
    padding: 16px;
  }
  
  .registry-inventory-controls {
    margin-bottom: 16px;
  }
}


/* Column widths */
.registry-inventory-table th:nth-child(1),
.registry-inventory-table td:nth-child(1) {
  width: 25%;
}

.registry-inventory-table th:nth-child(2),
.registry-inventory-table td:nth-child(2) {
  width: 15%;
}

.registry-inventory-table th:nth-child(3),
.registry-inventory-table td:nth-child(3) {
  width: 15%;
}

.registry-inventory-table th:nth-child(4),
.registry-inventory-table td:nth-child(4) {
  width: 10%;
}

.registry-inventory-table th:nth-child(5),
.registry-inventory-table td:nth-child(5) {
  width: 10%;
}

.registry-inventory-table th:nth-child(6),
.registry-inventory-table td:nth-child(6),
.registry-inventory-table th:nth-child(7),
.registry-inventory-table td:nth-child(7) {
  width: 10%;
}

.registry-inventory-table th:last-child,
.registry-inventory-table td:last-child {
  width: 15%;
  text-align: center;
}


/* Empty state styling */
.registry-inventory-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: #a0aec0;
  font-size: 16px;
  text-align: center;
}

.registry-inventory-empty-state::before {
  content: "";
  display: block;
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23CBD5E0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='21 8 21 21 3 21 3 8'%3E%3C/polyline%3E%3Crect x='1' y='3' width='22' height='5'%3E%3C/rect%3E%3Cline x1='10' y1='12' x2='14' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.5;
}

/* Pagination controls (optional) */
.registry-inventory-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #f8fafc;
  border-top: 1px solid #e2e8f0;
}


/* Responsive adjustments */
@media (max-width: 1280px) {
  .registry-inventory-filters-sort-container {
    flex-wrap: wrap;
  }
  
  .registry-inventory-filters-section {
    flex: 1 1 100%;
  }
  
  .registry-inventory-sort-section {
    flex: 1 1 100%;
    flex-direction: row;
    align-items: center;
  }
  
  .sort-label {
    margin-right: 12px;
  }
  
  .registry-inventory-modal-content {
    padding: 20px;
  }
  
  .registry-inventory-table th,
  .registry-inventory-table td {
    padding: 14px 16px;
  }
}

@media (max-width: 768px) {
  .registry-inventory-modal {
    width: 95%;
    height: 90%;
  }
  
  .registry-inventory-search-filters {
    flex-direction: column;
  }
  
  .registry-inventory-filters-section {
    flex-direction: column;
  }
  
  .registry-inventory-sort-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sort-controls {
    width: 100%;
  }
  
  .registry-inventory-table th:nth-child(2),
  .registry-inventory-table td:nth-child(2),
  .registry-inventory-table th:nth-child(6),
  .registry-inventory-table td:nth-child(6),
  .registry-inventory-table th:nth-child(7),
  .registry-inventory-table td:nth-child(7) {
    display: none;
  }
}



/* Special responsive behavior for filters and sort section */
@media (max-width: 900px) {
  .registry-inventory-filters-sort-container {
    flex-direction: column;
  }
  
  .registry-inventory-sort-section {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .sort-label {
    margin-right: 10px;
  }
}

/* Handle even smaller screens */
@media (max-width: 500px) {
  .registry-inventory-filters-section {
    flex-direction: column;
  }
  
  .registry-inventory-sort-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sort-controls {
    width: 100%;
  }


}


/* Improved action buttons in the registry inventory modal */
.registry-inventory-table .actions-cell {
  white-space: nowrap;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.registry-inventory-table .registry-btn-edit,
.registry-inventory-table .registry-btn-delete {
  padding: 8px 14px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.registry-inventory-table .registry-btn-edit {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
  color: white;
}

.registry-inventory-table .registry-btn-edit:hover {
  background: linear-gradient(120deg, #2c5282 0%, #2b6cb0 100%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.registry-inventory-table .registry-btn-delete {
  background-color: white;
  color: #0d1d31;
  border: 1px solid rgb(121, 122, 124);
}

.registry-inventory-table .registry-btn-delete:hover {
  background-color: #de0404;
  color: #ffffff;
  border: 1px solid #de0404;

}





/* Focus state for accessibility */
.registry-inventory-table .registry-btn-edit:focus,
.registry-inventory-table .registry-btn-delete:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .registry-inventory-table .actions-cell {
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .registry-inventory-table .registry-btn-edit,
  .registry-inventory-table .registry-btn-delete {
    padding: 6px 10px;
    min-width: 60px;
  }
}









/* Required field indicator */
.required {
  color: #e53e3e;
  margin-left: 2px;
}

/* Message styling */
.registry-error,
.registry-success {
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.registry-error {
  background-color: rgba(245, 101, 101, 0.08);
  color: #e53e3e;
  border-left: 4px solid #e53e3e;
}

.registry-success {
  background-color: rgba(76, 153, 175, 0.1);
  color: #1091cc;
  border-left: 4px solid #1091cc;
}

/* Form section styling */
.registry-form-section {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

/* Form styling */
.registry-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.registry-form-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.registry-form-group {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.registry-form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.registry-form-group input,
.registry-form-group select,
.registry-form-group textarea {
  padding: 12px 14px;
  border: 1px solid #bcc7d3;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  min-height: 44px;
}

.registry-form-group input:focus,
.registry-form-group select:focus,
.registry-form-group textarea:focus {
  outline: none;
  border-color: #4299e1;
}

.registry-form-group input:disabled,
.registry-form-group select:disabled,
.registry-form-group textarea:disabled {
  background-color: #edf2f7;
  border-color: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
}

/* Barcode input group */
.barcode-input-group {
  display: flex;
  gap: 10px;
}

.barcode-input-group input {
  flex: 1;
}

.barcode-search-button {
  padding: 0 20px;
  border-radius: 8px;
  background: linear-gradient(120deg, #2c5282 0%, #1a365d 100%);
  color: white;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.barcode-search-button:hover {
  background: linear-gradient(120deg, #2b4970 0%, #182848 100%);
}

/* Radio buttons for unit/pack */
.unit-pack-selector {
  display: flex;
  gap: 20px;
  margin-top: 5px;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #4a5568;
  cursor: pointer;
}

.radio-label input[type="radio"] {
  margin: 0;
  width: 16px;
  height: 16px;
}

/* Expiration inputs */
.expiration-group {
  min-width: 250px;
}

.expiration-inputs {
  display: flex;
  gap: 10px;
}

.expiration-inputs input {
  flex: 1;
}

.expiration-inputs select {
  flex: 1;
}

/* Checkbox container */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: auto;
  min-height: 44px;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0;
}

/* Form actions */
.registry-form-actions {
  display: flex;
  gap: 16px;
  margin-top: 10px;
}

.registry-submit-btn,
.registry-cancel-btn {
  height: 44px;
  padding: 0 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.registry-submit-btn {
  background: linear-gradient(120deg, #2c5282 0%, #1a365d 100%);
  color: white;
  border: none;
  flex: 1;
}

.registry-submit-btn:hover:not(:disabled) {
  background: linear-gradient(120deg, #2b4970 0%, #182848 100%);
}

.registry-submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.registry-cancel-btn {
  background-color: #edf2f7;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  flex-basis: 120px;
  flex-shrink: 0;
}

.registry-cancel-btn:hover {
  background-color: #e2e8f0;
  color: #2d3748;
}

/* Registry list section */
.registry-list-section {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.registry-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.registry-search {
  width: 300px;
}

.registry-search-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.2s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23718096' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
  padding-left: 40px;
}

.registry-search-input:focus {
  outline: none;
  border-color: #4299e1;
}

/* Table styling */
.registry-table-container {
  overflow-x: auto;
}

.registry-table {
  width: 100%;
  border-collapse: collapse;
  color: #2d3748;
}

.registry-table th {
  background-color: #f8fafc;
  padding: 14px 20px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #e2e8f0;
  color: #4a5568;
  position: sticky;
  top: 0;
  z-index: 10;
}

.registry-table td {
  padding: 16px 20px;
  border-bottom: 1px solid #edf2f7;
  font-size: 14px;
  vertical-align: middle;
}

.registry-table tr:hover {
  background-color: #f8fafc;
}

/* Action buttons in table */
.actions-cell {
  white-space: nowrap;
  display: flex;
  gap: 8px;
}

.registry-btn-edit,
.registry-btn-delete {
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
  min-width: 60px;
  text-align: center;
}

.registry-btn-edit {
  background-color: #4299e1;
  color: white;
}

.registry-btn-edit:hover {
  background-color: #3182ce;
}

.registry-btn-delete {
  background-color: rgba(245, 101, 101, 0.1);
  color: #e53e3e;
}

.registry-btn-delete:hover {
  background-color: rgba(245, 101, 101, 0.2);
}


/* Loading indicator */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  color: #4a5568;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .registry-header {
    padding: 20px;
  }
  
  .registry-title {
    font-size: 24px;
  }
  
  .registry-subtitle {
    font-size: 14px;
  }
  
  .registry-main {
    padding: 0 15px;
  }
  
  .registry-form-section,
  .registry-list-section {
    padding: 20px;
  }
  
  .registry-form-row {
    flex-direction: column;
    gap: 16px;
  }
  
  .registry-form-group {
    min-width: 100%;
  }
  
  .barcode-input-group {
    flex-direction: column;
    gap: 8px;
  }
  
  .barcode-search-button {
    height: 44px;
  }
  
  .unit-pack-selector {
    flex-direction: column;
    gap: 10px;
  }
  
  .expiration-inputs {
    flex-direction: column;
    gap: 8px;
  }
  
  .registry-form-actions {
    flex-direction: column;
  }
  
  .registry-submit-btn,
  .registry-cancel-btn {
    width: 100%;
  }
  
  .registry-list-header {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }
  
  .registry-search {
    width: 100%;
  }
  
  .actions-cell {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}