.pin-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .pin-confirmation-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .pin-confirmation-container h3 {
    margin-top: 0;
    color: #333;
  }
  
  .pin-confirmation-container input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .pin-confirmation-container .error-message {
    color: #d32f2f;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 15px;
  }
  
  .button-confirm,
  .button-cancel {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .button-confirm {
    background-color: #2196f3;
    color: white;
  }
  
  .button-cancel {
    background-color: #f5f5f5;
    color: #333;
  }
  
  .button-confirm:hover {
    background-color: #1976d2;
  }
  
  .button-cancel:hover {
    background-color: #e0e0e0;
  }