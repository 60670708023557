/* BrokerDashboard.css */
.broker-dashboard {
  padding: 90px 20px 30px;
  max-width: 1400px;
  margin: 0 auto;
  background-color: #f9fafb;
  min-height: 100vh;
}

.dashboard-header {
  margin-bottom: 30px;
}

.dashboard-header h1 {
  font-size: 28px;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 8px;
}

.dashboard-subtitle {
  color: #6b7280;
  font-size: 16px;
  margin: 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.stat-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}

.leads-icon {
  background-color: rgba(249, 115, 22, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f97316' viewBox='0 0 24 24'%3E%3Cpath d='M12 12a5 5 0 110-10 5 5 0 010 10zm0-2a3 3 0 100-6 3 3 0 000 6zm9 11a1 1 0 01-2 0v-2a3 3 0 00-3-3H8a3 3 0 00-3 3v2a1 1 0 01-2 0v-2a5 5 0 015-5h8a5 5 0 015 5v2z'/%3E%3C/svg%3E");
}

.partners-icon {
  background-color: rgba(79, 70, 229, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234f46e5' viewBox='0 0 24 24'%3E%3Cpath d='M12 5a3 3 0 11-6 0 3 3 0 016 0zm-3 8a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zm9.31-2a3 3 0 010-4 3 3 0 010 4zm.69 5v1h4v-1a4.99 4.99 0 00-2.77-4.47 6.33 6.33 0 00-1.23 4.47zm-15 3v-1a6.33 6.33 0 00-1.23-4.47A4.99 4.99 0 002 16v1h2z'/%3E%3C/svg%3E");
}

.deals-icon {
  background-color: rgba(16, 185, 129, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2310b981' viewBox='0 0 24 24'%3E%3Cpath d='M8 7V5c0-1.1.9-2 2-2h4a2 2 0 012 2v2h4a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V9c0-1.1.9-2 2-2h4zm8 2H8v10h8V9zm2 0v10h2V9h-2zM6 9H4v10h2V9zm6-4h-4v2h4V5z'/%3E%3C/svg%3E");
}

.revenue-icon {
  background-color: rgba(6, 182, 212, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2306b6d4' viewBox='0 0 24 24'%3E%3Cpath d='M12 22a10 10 0 100-20 10 10 0 000 20zm1-14v-2h-2v2H9a1 1 0 00-1 1v2h6v2H8v4h2v2h2v-2h2a1 1 0 001-1v-2H9v-2h6v-4h-2z'/%3E%3C/svg%3E");
}

.stat-content {
  flex-grow: 1;
}

.stat-content h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 5px;
  color: #1f2937;
}

.stat-content p {
  color: #6b7280;
  margin: 0;
  font-size: 14px;
}

.stat-link {
  position: absolute;
  bottom: 12px;
  right: 16px;
  color: #1e40af;
  font-size: 13px;
  text-decoration: none;
  font-weight: 500;
}

.stat-link:hover {
  text-decoration: underline;
}

.dashboard-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.dashboard-left, .dashboard-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.broker-info-card, .tasks-card, .notifications-card, .recent-activity-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-header h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #1f2937;
}

.edit-link, .view-all-link {
  color: #1e40af;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.edit-link:hover, .view-all-link:hover {
  text-decoration: underline;
}

.edit-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%231e40af' viewBox='0 0 24 24'%3E%3Cpath d='M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z'/%3E%3C/svg%3E");
  background-size: contain;
}

.broker-profile-summary {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.broker-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #1e40af;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  margin-right: 15px;
}

.broker-details {
  flex-grow: 1;
}

.broker-details h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px;
  color: #1f2937;
}

.broker-id {
  color: #6b7280;
  margin: 0 0 8px;
  font-size: 14px;
}

.account-status {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
}

.status-indicator.active {
  background-color: #10b981;
}

.info-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.info-item {
  display: flex;
  flex-direction: column;
}

.info-label {
  color: #6b7280;
  font-size: 13px;
  margin: 0 0 4px;
}

.info-value {
  color: #1f2937;
  font-weight: 500;
  margin: 0;
}

.tasks-list, .notification-list, .activity-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.task-item {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  background-color: #f9fafb;
}

.task-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #d1d5db;
  margin-right: 12px;
  cursor: pointer;
}

.task-content {
  flex-grow: 1;
}

.task-content p {
  margin: 0 0 4px;
  font-weight: 500;
}

.task-due {
  font-size: 12px;
  color: #ef4444;
}

.add-task-btn {
  width: 100%;
  padding: 10px;
  background-color: #f3f4f6;
  color: #4b5563;
  border: 1px dashed #d1d5db;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.add-task-btn:hover {
  background-color: #e5e7eb;
  color: #1f2937;
}

.notification-item {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  border-radius: 6px;
  background-color: #f9fafb;
  position: relative;
}

.notification-item.unread::before {
  content: '';
  position: absolute;
  top: 12px;
  right: 12px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1e40af;
}

.notification-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
}

.message-icon {
  background-color: rgba(79, 70, 229, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234f46e5' viewBox='0 0 24 24'%3E%3Cpath d='M8 9a1 1 0 011-1h8a1 1 0 110 2H9a1 1 0 01-1-1zm-2 4a1 1 0 011-1h10a1 1 0 110 2H7a1 1 0 01-1-1zm3 4a1 1 0 100 2h4a1 1 0 100-2H9z'/%3E%3Cpath d='M4 4v16a2 2 0 002 2h12a2 2 0 002-2V8.342a2 2 0 00-.602-1.43l-4.44-4.342A2 2 0 0013.56 2H6a2 2 0 00-2 2zm16 0v4h-4V4h4z'/%3E%3C/svg%3E");
}

.approval-icon {
  background-color: rgba(16, 185, 129, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2310b981' viewBox='0 0 24 24'%3E%3Cpath d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'/%3E%3C/svg%3E");
}

.system-icon {
  background-color: rgba(245, 158, 11, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f59e0b' viewBox='0 0 24 24'%3E%3Cpath d='M12 19l9 2-9-18-9 18 9-2zm0 0v-8'/%3E%3C/svg%3E");
}

.notification-content {
  flex-grow: 1;
}

.notification-content p {
  margin: 0 0 4px;
  font-weight: 500;
}

.notification-time, .activity-time {
  font-size: 12px;
  color: #6b7280;
}

.activity-item {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  border-radius: 6px;
  background-color: #f9fafb;
}

.activity-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
}

.lead-activity-icon {
  background-color: rgba(249, 115, 22, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f97316' viewBox='0 0 24 24'%3E%3Cpath d='M12 4.354a4 4 0 110 5.292V14a1 1 0 011 1v1a1 1 0 01-1 1v1a1 1 0 001 1v1a1 1 0 01-1 1h-2a1 1 0 01-1-1v-1a1 1 0 001-1v-1a1 1 0 01-1-1v-1a1 1 0 011-1V9.646a4 4 0 010-5.292z'/%3E%3C/svg%3E");
}

.partner-activity-icon {
  background-color: rgba(79, 70, 229, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234f46e5' viewBox='0 0 24 24'%3E%3Cpath d='M12 4.354a4 4 0 110 5.292V14a1 1 0 011 1v1a1 1 0 01-1 1v1a1 1 0 001 1v1a1 1 0 01-1 1h-2a1 1 0 01-1-1v-1a1 1 0 001-1v-1a1 1 0 01-1-1v-1a1 1 0 011-1V9.646a4 4 0 010-5.292z'/%3E%3C/svg%3E");
}

.deal-activity-icon {
  background-color: rgba(16, 185, 129, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2310b981' viewBox='0 0 24 24'%3E%3Cpath d='M9 12l2 2 4-4m5.618-4.016l-11-4a1 1 0 00-1.236 1.236l4 11a1 1 0 001.236.764l11-4a1 1 0 000-1.764l-11-4a1 1 0 00-1.236 1.236l4 11a1 1 0 001.236.764l11-4a1 1 0 000-1.764l-11-4z'/%3E%3C/svg%3E");
}

.finance-activity-icon {
  background-color: rgba(6, 182, 212, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2306b6d4' viewBox='0 0 24 24'%3E%3Cpath d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'/%3E%3C/svg%3E");
}

.activity-content {
  flex-grow: 1;
}

.activity-content p {
  margin: 0 0 4px;
  font-weight: 500;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f4f6;
  border-top: 4px solid #1e40af;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1024px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .dashboard-content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 640px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
  .info-grid-container {
    grid-template-columns: 1fr;
  }
}