.admin-layout {
    display: flex;
    min-height: 100vh;
    background-color: #f5f6fa;
  }
  
  /* Sidebar Styles */
  .sidebar {
    width: 260px;
    background: #ffffff;
    border-right: 1px solid #e1e4e8;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .sidebar-closed .sidebar {
    width: 70px;
  }
  
  .sidebar-header {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e1e4e8;
  }
  
  .logo {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2563eb;
  }
  
  .sidebar-closed .logo {
    display: none;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.375rem;
  }
  
  .toggle-btn:hover {
    background-color: #f1f5f9;
  }
  
  .sidebar-nav {
    padding: 1rem 0;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    color: #64748b;
    text-decoration: none;
    transition: all 0.2s ease;
    gap: 0.75rem;
  }
  
  .nav-item:hover {
    background-color: #f1f5f9;
    color: #2563eb;
  }
  
  .nav-item.active {
    background-color: #eff6ff;
    color: #2563eb;
    font-weight: 500;
  }
  
  .sidebar-closed .nav-item span {
    display: none;
  }
  
  .sidebar-footer {
    padding: 1rem 0;
    border-top: 1px solid #e1e4e8;
  }
  
  .logout-btn {
    width: 100%;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
    font-size: 1rem;
  }
  
  /* Top Header Styles */
  .top-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 260px;
    height: 64px;
    background: #ffffff;
    border-bottom: 1px solid #e1e4e8;
    z-index: 900;
    transition: left 0.3s ease;
  }
  
  .sidebar-closed .top-header {
    left: 70px;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    height: 100%;
  }
  
  .header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .mobile-menu-btn {
    display: none;
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
    padding: 0.5rem;
  }
  
  .page-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1e293b;
  }
  
  .header-right {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .notification-btn {
    position: relative;
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
    padding: 0.5rem;
  }
  
  .notification-badge {
    position: absolute;
    top: 0;
    right: 0;
    background: #0091ff;
    color: white;
    font-size: 0.75rem;
    padding: 0.125rem 0.375rem;
    border-radius: 9999px;
  }
  
  .admin-profile {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .admin-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .admin-name {
    font-size: 0.875rem;
    color: #1e293b;
    font-weight: 500;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-100%);
    }
  
    .sidebar-open .sidebar {
      transform: translateX(0);
      width: 260px;
    }
  
    .top-header {
      left: 0;
    }
  
    .sidebar-open .top-header {
      left: 260px;
    }
  
    .mobile-menu-btn {
      display: block;
    }
  
    .admin-name {
      display: none;
    }
  }