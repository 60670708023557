/* ================ General Container Styles ================ */


@media (max-width: 1200px) {
  .orders-container {
    max-width: 1380px;
    margin: 0 auto;
    padding: 20px;
      background-color: #f7fafc;
    min-height: calc(100vh - 60px); /* Adjusted for header */
  }
}

@media (min-width: 1201px) {
  .orders-container {
    max-width: 1380px;
    margin: 0 auto;
    padding: 20px;
    padding-left: 305px;
    background-color: #f7fafc;
    min-height: calc(100vh - 60px); /* Adjusted for header */
  }
}


@media (max-width: 1200px) {
  .create-order-container,
  .order-details-container,
  .check-in-container {
    max-width: 1380px;
    margin: 0 auto;
    padding: 24px;
    background-color: #f7fafc;
    min-height: calc(100vh - 60px); /* Adjusted for header */
  }
}

@media (min-width: 1201px) {
  .create-order-container,
  .order-details-container,
  .check-in-container {
    max-width: 1380px;
    margin: 0 auto;
    padding: 24px;
    padding-left: 305px;
    background-color: #f7fafc;
    min-height: calc(100vh - 60px); /* Adjusted for header */
  }
}


/* .create-order-container,
.order-details-container,
.check-in-container {
  max-width: 1380px;
  margin: 0 auto;
  padding: 24px;
  background-color: #f7fafc;
} */

/* ================ Header & Section Titles ================ */
.orders-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.orders-section-title {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.orders-subsection-title {
  font-size: 16px;
  font-weight: 600;
  color: #4a5568;
  margin: 20px 0 15px 0;
}

.orders-section-divider {
  height: 1px;
  background-color: #e2e8f0;
  margin: 24px 0;
  border: none;
}

/* ================ Messages (Error & Success) ================ */
.orders-error,
.orders-success {
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.orders-error {
  background-color: rgba(245, 101, 101, 0.08);
  color: #e53e3e;
  border-left: 4px solid #e53e3e;
}

.orders-success {
  background-color: rgba(76, 153, 175, 0.1);
  color: #1091cc;
  border-left: 4px solid #1091cc;
}

.orders-loading,
.orders-error-display {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  font-size: 16px;
  color: #4a5568;
  margin-top: 60px;
}

.orders-error-display {
  color: #e53e3e;
}

.orders-empty-state {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  color: #718096;
  font-size: 16px;
  margin-top: 20px;
}

/* ================ Button Styles ================ */
.orders-create-btn,
.orders-primary-btn,
.orders-secondary-btn,
.orders-add-btn,
.orders-remove-btn,
.orders-submit-btn,
.orders-sort-btn {
  height: 36px;
  padding: 0 14px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.orders-create-btn {
  background: linear-gradient(120deg, #2c5282 0%, #1a365d 100%);
  color: white;
  border: none;
}

.orders-create-btn:hover:not(:disabled) {
  background: linear-gradient(120deg, #2b4970 0%, #182848 100%);
  transform: translateY(-1px);
}

.orders-create-btn:active {
  transform: translateY(0);
}

.orders-primary-btn {
  background: linear-gradient(120deg, #2c5282 0%, #1a365d 100%);
  color: white;
  border: none;
}

.orders-primary-btn:hover:not(:disabled) {
  background: linear-gradient(120deg, #2b4970 0%, #182848 100%);
}

.orders-secondary-btn {
  background-color: #edf2f7;
  color: #4a5568;
  border: 1px solid #e2e8f0;
}

.orders-secondary-btn:hover {
  background-color: #e2e8f0;
  color: #2d3748;
}

.orders-add-btn {
  background-color: #4299e1;
  color: white;
  border: none;
  width: 100%;
  margin-top: 16px;
}

.orders-add-btn:hover:not(:disabled) {
  background-color: #3182ce;
}

.orders-add-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.orders-remove-btn {
  background-color: rgba(245, 101, 101, 0.1);
  color: #e53e3e;
  border: none;
  height: 36px;
  min-width: 80px;
}

.orders-remove-btn:hover {
  background-color: rgba(245, 101, 101, 0.2);
}

.orders-submit-btn {
  background: linear-gradient(120deg, #2c5282 0%, #1a365d 100%);
  color: white;
  border: none;
  width: 100%;
  height: 48px;
  margin-top: 24px;
}

.orders-submit-btn:hover:not(:disabled) {
  background: linear-gradient(120deg, #2b4970 0%, #182848 100%);
}

.orders-submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.orders-sort-btn {
  background-color: #ffffff;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  padding: 0 12px;
}

.orders-sort-btn:hover {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

/* ================ Order Card Styles ================ */
.orders-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.order-card {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.order-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.order-card-header {
  padding: 12px 16px;
  background-color: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-number {
  font-weight: 600;
  color: #2d3748;
  font-size: 15px;
}

.order-card-body {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.order-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-info-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.order-info-label {
  color: #718096;
  font-weight: 500;
  min-width: 70px;
}

.order-info-value {
  color: #2d3748;
}

.order-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7fafc;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  color: #4a5568;
  font-weight: 500;
}

.order-card-actions {
  padding: 12px 16px;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* ================ Status Badge Styles ================ */
.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: inline-block;
}

.status-receiving {
  background-color: rgba(66, 153, 225, 0.1);
  color: #3182ce;
}

.status-supplying {
  background-color: rgba(72, 187, 120, 0.1);
  color: #38a169;
}

.status-pending {
  background-color: rgba(237, 137, 54, 0.1);
  color: #dd6b20;
}

.status-completed {
  background-color: rgba(72, 187, 120, 0.1);
  color: #38a169;
}

.status-cancelled {
  background-color: rgba(160, 174, 192, 0.1);
  color: #718096;
}

/* ================ Forms and Inputs ================ */
.orders-form-container {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.orders-form-row {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
}

.orders-form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.orders-form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.orders-input,
.orders-select {
  padding: 12px 14px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.2s ease;
  min-height: 44px;
}

.orders-input:focus,
.orders-select:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.1);
}

.orders-input:disabled,
.orders-select:disabled {
  background-color: #edf2f7;
  border-color: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
}

.orders-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.date-input-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin: 0;
}

.checkbox-container input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.notify-inputs-wrapper {
  display: flex;
  gap: 12px;
}

.helper-text {
  font-size: 13px;
  color: #d34001;
  font-style: italic;
  margin-top: 4px;
}

.orders-form-actions {
  margin-top: 24px;
}

/* ================ Order Items List Styles ================ */
.order-items-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.order-item-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8fafc;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.order-item-details {
  display: flex;
  align-items: center;
  gap: 12px;
}

.order-item-name {
  font-weight: 500;
  color: #2d3748;
}

.order-item-quantity {
  background-color: #edf2f7;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  color: #4a5568;
}

.order-item-category {
  color: #718096;
  font-size: 13px;
}

/* ================ Order Details Styles ================ */
.order-details-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
}

.order-details-info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.order-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.order-item-detail-card {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.order-item-title {
  font-size: 16px;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #edf2f7;
}

.order-item-properties {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-item-property {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.property-label {
  color: #718096;
  font-weight: 500;
}

.property-value {
  color: #2d3748;
}

/* ================ Check-In Table Styles ================ */
.table-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-top: 20px;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th {
  background-color: #f8fafc;
  padding: 14px 16px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #e2e8f0;
  color: #4a5568;
}

.orders-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #edf2f7;
  font-size: 14px;
  color: #2d3748;
}

.orders-table tr:hover {
  background-color: #f8fafc;
}

.orders-table tr:last-child td {
  border-bottom: none;
}

.measure-input {
  display: flex;
  gap: 8px;
  align-items: center;
}

.measure-input input {
  width: 80px;
  padding: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

.measure-input select {
  min-width: 120px;
  padding: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

/* ================ Controls Section Styles ================ */
.orders-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  gap: 16px;
}

.orders-search-filter {
  flex: 1;
  max-width: 400px;
}

.orders-search-input {
  width: 100%;
  padding: 12px 14px 12px 40px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23718096' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
  transition: all 0.2s ease;
}

.orders-search-input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.1);
}

.orders-sort-controls {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* ================ Responsive Styles ================ */
@media (max-width: 1024px) {
  .orders-form-row,
  .order-details-info {
    grid-template-columns: 1fr;
  }
  
  .order-items-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

@media (max-width: 768px) {
  .orders-header-row,
  .orders-controls,
  .order-card-body {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .orders-search-filter {
    max-width: 100%;
    width: 100%;
  }
  
  .orders-sort-controls {
    width: 100%;
    justify-content: space-between;
  }
  
  .order-card-actions {
    justify-content: space-between;
  }
  
  .orders-form-row {
    flex-direction: column;
  }
  
  .date-input-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .notify-inputs-wrapper {
    width: 100%;
  }
  
  .check-in-actions {
    flex-wrap: wrap;
  }
  
  .check-in-actions button {
    flex: 1;
    min-width: 120px;
  }
  
  .measure-input {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .measure-input input,
  .measure-input select {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .order-item-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .order-card-actions {
    flex-direction: column;
  }
  
  .order-card-actions button {
    width: 100%;
  }
  
  .orders-table {
    font-size: 13px;
  }
  
  .orders-table th,
  .orders-table td {
    padding: 10px 12px;
  }
}