.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
  justify-content: flex-end;
}

.mobile-menu-overlay.open {
  display: flex;
}

.mobile-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1000;
  width: 80%;
  max-width: 400px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.mobile-menu-container.open {
  transform: translateX(0);
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.mobile-menu-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.mobile-menu-close {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  z-index: 1001;
}

.mobile-nav {
  flex-grow: 1;
  overflow-y: auto;
}

.mobile-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-nav li {
  border-bottom: 1px solid #e0e0e0;
}

.mobile-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.mobile-menu-item:hover {
  background-color: #f0f0f0;
  color: #035cc9;
}

.mobile-menu-item.active {
  color: #035cc9 !important;
}

.mobile-menu-item.back-to-inventory-item {
  background-color: #0055bd;
  color: #ffffff !important;
}

.mobile-menu-item.back-to-inventory-item:hover {
  background-color: #01408e;
}



.mobile-menu-search-container-n {
  border-bottom: 1px solid #e0e0e0;
}

.mobile-menu-search-n {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.5rem;
  border-radius: 4px;
}

.mobile-menu-search-n input {
  border: none;
  background: none;
  padding: 0.5rem;
  margin-left: 0.5rem;
  width: 100%;
  outline: none;
}

/* Media query for mobile view */
@media (max-width: 75vw) {
  .mobile-menu-overlay {
      /* Only show when menu is open */
      display: none;
  }
  
  .mobile-menu-overlay.open {
      display: flex;
  }

  /* Ensure main content is visible when menu is closed */
  .finances-content {
      z-index: 1;
  }
}
