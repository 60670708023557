.dax-ai-container {
  display: flex;
  height: calc(100vh - 62px);
  background-color: #1b222b;
  color: #f6f3f3;
  overflow: hidden;
  transition: padding-left 0.3s ease;
  margin-top: 62px;
}

.dax-ai-container.sidebar-pinned {
  padding-left: 270px;
}

.dax-ai-container.sidebar-unpinned {
  padding-left: 0;
}

.dax-chat-sidebar {
  width: 270px;
  background-color: #0f1923;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #1b222b;
  position: fixed;
  top: 62px;
  left: 0;
  bottom: 0;
  z-index: 1000;
  transition: transform 0.3s ease;
}


.dax-chat-sidebar.unpinned {
  transform: translateX(-270px);
}

.dax-chat-sidebar.unpinned.visible {
  transform: translateX(0);
}

.dax-chat-sidebar.pinned {
  transform: translateX(0);
}


.dax-chat-main-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: margin-left 0.3s ease;
}

.sidebar-header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px;
}


.sidebar-header-h1 {
font-size: 24px;
font-weight: 100;
color: #ffffff;
margin: 0;
text-align: left;
margin-left: 5px;
}


.unpin-sidebar {
background: none;
border: none;
color: #ffffff;
cursor: pointer;
padding: 0;
}


.toggle-sidebar {
background: none;
border: none;
color: #ffffff;
cursor: pointer;
padding: 0;
}


.new-dax-chat-btn {
padding: 15px;
background-color: #1e446b6c;
color: #ffffff;
border: none;
cursor: pointer;
font-size: 14px;
font-weight: bold;
text-align: left;
transition: background-color 0.3s ease;
}

.new-dax-chat-btn:hover {
background-color: #15395c;
}

.dax-chat-list {
flex-grow: 1;
overflow-y: auto;
padding: 10px;
}

.dax-chat-item {
padding: 10px;
cursor: pointer;
border-radius: 5px;
margin-bottom: 5px;
font-size: 14px;
transition: background-color 0.3s ease;
}

.dax-chat-item:hover,
.dax-chat-item.active {
background-color: #2c3e50;
}

.dax-user-email {
padding: 20px;
text-align: center;
font-size: 14px;
}

.dax-chat-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 20px;
  height: calc(100% - 90px);


}

.dax-chat-sidebar.unpinned + .dax-chat-main {
margin-left: 0;
}

.dax-chat-messages {
flex-grow: 1;
padding: 20px;
display: flex;
flex-direction: column;
}

.message {
display: flex;
margin-bottom: 20px;
}

.message.user {
align-self: flex-end;
flex-direction: row;
max-width: 700px;
line-height: 1.5;

}

.message.assistant {
align-self: flex-start;
max-width: 800px;
line-height: 1.5;

}

.message-avatar {
width: 30px;
height: 30px;
border-radius: 50%;
background-color: #0648ab;
display: flex;
align-items: center;
justify-content: center;
font-weight: 100;
font-size: 12px;
margin-right: 10px;
margin-left: 10px;
}

.message.user .message-avatar {
background-color: #ececec;
}





.message-content.thinking {
  background: none;
 
}



.dax-chat-input-wrapper {
position: fixed;
bottom: 0;
left: 270px; /* Width of the sidebar */
right: 0;
background-color: #1b222b;
display: flex;
flex-direction: column;
align-items: center;
margin-top: 10px;
}

.sidebar-unpinned .dax-chat-input-wrapper {
  left: 0;
}

.dax-chat-input-form {
  
width: 100%;
max-width: 850px;
display: flex;
justify-content: center;
box-sizing: border-box;
}

.dax-chat-input-container {
  
width: 100%;
position: relative;

}

.dax-chat-input {
width: 100%;
padding: 15px 50px 15px 20px;
border: none !important;
border-radius: 25px;
background-color: #ffffff !important;
color: #000000 !important;
font-size: 16px !important;
box-sizing: border-box;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
height: 105px;
padding-bottom: 63px !important;

}

.dax-chat-input::placeholder {
color: #999999;
}


.dax-chat-input-p{
color: #9ca1a1;
text-align: center;
font-size: 12px;
margin-top: 5px;
margin-bottom: 10px;
}

.dax-send-btn {
  position: absolute;
  right: 5px;
  top: calc(100% - 75px) !important;
  transform: translateY(-50%);
  background-color: #30404a;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

}

.dax-send-btn:disabled {
background-color: #9ba7a7 !important;
cursor: not-allowed;
}

.dax-send-btn:disabled:hover {
  background-color: #9ba7a7 !important;
  cursor: not-allowed;
  }

.dax-send-btn svg {
width: 20px;
height: 20px;
}

.dax-send-icon {
  font-size: 18.5px;
  line-height: 1;
}


.dax-send-btn:hover{
background-color: #282f3b !important;
}

.dax-chat-item {
position: relative;
display: flex;
align-items: center;
padding: 10px;
cursor: pointer;
border-radius: 5px;
margin-bottom: 5px;
user-select: none; /* Prevent text selection */

}

.dax-chat-item:hover,
.dax-chat-item.options-active {
background-color: rgba(255, 255, 255, 0.1);
}

.dax-chat-item.active {
background-color: #2c3e50;
}

.dax-chat-item-content {
flex-grow: 1;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
padding-right: 24px; 

}
.dax-chat-item a {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1; /* Ensure the link is clickable */
}

.dax-chat-item-content input {
width: 100%;
background-color: transparent;
border: none;
color: #ffffff;
font-size: inherit;
}

.dax-chat-item-content input:focus {
outline: none;
border-bottom: 1px solid #ffffff;
}

.options-btn {
position: absolute;
right: 10px;
top: 50%;
transform: translateY(-50%);
background: none;
border: none;
color: #ffffff;
cursor: pointer;
padding: 0;
margin: 0;
opacity: 0;
transition: opacity 0.3s ease;
}

.dax-chat-item:hover .options-btn,
.dax-chat-item.options-active .options-btn {
opacity: 1;
}

.options-menu {
position: absolute;
right: 10px;
top: 100%;
background-color: #1e2a3a;
border-radius: 5px;
z-index: 10;
margin-top: 5px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.options-menu button {
display: flex;
align-items: center;
padding: 8px 12px;
width: 100%;
text-align: left;
background: none;
border: none;
color: #ffffff;
cursor: pointer;
}

.options-menu button:hover {
background-color: rgba(255, 255, 255, 0.1);
}

.options-menu button svg {
margin-right: 8px;
}

.options-menu .delete-btn {
color: #e74c3c;
}

.dax-chat-item input[type="text"] {
width: 100%;
background-color: #2c3e50 !important ;
color: #ffffff;
border: none;
padding: 3px;
}

.dax-chat-item input[type="text"]:focus {
outline: none;
}

@media screen and (min-width: 75vw) {


  .dax-chat-main {
    align-items: center;
  }
  
.dax-chat-messages {
  width: 80%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.message {
  max-width: 100%;
}

.dax-chat-input-form {
  display: flex;
  justify-content: center;
  background-color: #1b222b;
  padding-bottom: 10px;
  max-width: 80%;
  bottom: 0;

}

.dax-chat-input-container {
  max-width: 850px;
  position: relative;
}

.dax-chat-input {
  width: 100%;
  padding: 15px 50px 15px 20px;
  border: none;
  border-radius: 25px;
  background-color: #ffffff;
  color: #000000 !important;
  font-size: 14px;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dax-send-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #30404a !important;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dax-chat-input-p {
  color: #9ca1a1;
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 850px;

}

.message-content {
  max-width: calc(100% - 86px);
}

.document-preview-container-wrapper{
  width: 80% !important;
}

}


/* Ensure the input area stays at the bottom */


/* Custom scrollbar styles for WebKit browsers */
.dax-chat-main::-webkit-scrollbar {
width: 10px;
}

.dax-chat-main::-webkit-scrollbar-track {
background: #1b222b;
}

.dax-chat-main::-webkit-scrollbar-thumb {
background: #888;
}

.dax-chat-main::-webkit-scrollbar-thumb:hover {
background: #555;
}


@media screen and (min-width: 75vw) {

.dax-chat-messages {
  width: 80%;
  max-width: 910px;
  margin-left: auto;
  margin-right: auto;
}

.dax-chat-input-wrapper {
  left: 270px;
  right: 0;
}

.dax-chat-input-form {
  width: 100%;
}

.dax-chat-main {
  margin-bottom: 143px;
}
}


@media screen and (max-width: 768px) {
  .document-preview {
    width: calc(100% - 20px);
  }
  .dax-send-btn {
    width: 36px;
    height: 36px;
  }

  .dax-send-icon {
    font-size: 20px;
  }

  .dax-send-btn svg {
    width: 18px;
    height: 18px;
  }

.dax-chat-main {
  margin-left: 0;
  margin-bottom: 80px;
}
}


/* Add to DaxAI.css */

.document-preview-container {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 5px;
  margin-top: 1px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 7px;

}

.document-preview-container-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 850px;
  margin-right: 5px;
  margin-left: 2px;

}

.document-icon {
  margin-right: 12px;
}

.document-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.document-title {
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

.document-type {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin-top: 2px;
}

.close-preview-btn {
  background: none;
  border: none;
  padding: 4px;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: 10px;
}

.close-preview-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.document-svg-icon {
  width: 32px;
  height: 32px;

}


.dax-input-info-connect-menu{
display: flex;
justify-content: flex-start;
width: calc(100% - 200px);
position: absolute;
left: 15px;
top: 83%;
transform: translateY(-50%);
color: #ffffff;
border: none;
align-items: center;
padding: 0;
width: 100%;
}






.dax-info-connect-buttons{
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  width: auto;
  background: linear-gradient(to bottom, #a7cdfc, #a7cdfc);
  border: none;
  margin-left: 6px;
  color: #020010;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 6px;
  padding: 2px 5px;
  min-width: 85px;
  justify-content: center;
}



.dax-input-info-left-bottom-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background: linear-gradient(to bottom, #a7cdfc, #a7cdfc);
  border: none;
  margin-left: 6px;
  color: #020010;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 50%;
}


.dax-input-info-left-bottom-icons:hover{

  background: linear-gradient(to bottom, #000038, #000038);
  color:  #ffffff;
}

.dax-info-connect-icon{
  color: #000000;

}

.dax-info-connect-p{
  font-size: 14px;
}


.dax-info-connect-icon:hover{
  background: linear-gradient(to bottom, #000038, #000038);
  color:  #ffffff;

}


.dax-info-connect-buttons:hover{
  background: linear-gradient(to bottom, #000038, #000038);
  color:  #ffffff;
}

.prose {
  max-width: 100%;
}

.prose h1,
.prose h2,
.prose h3,
.prose h4 {
  margin-top: 1.5em;
  margin-bottom: 0.75em;
  line-height: 1.3;
}

.prose p {
  margin-bottom: 1.25em;
}

.prose ul,
.prose ol {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.prose li {
  margin-bottom: 0.5em;
}

.prose blockquote {
  font-style: italic;
  border-left: 4px solid #4299e1;
  padding-left: 1rem;
  margin: 1.5em 0;
  color: #a0aec0;
}

.prose code {
  font-family: 'Fira Code', monospace;
  font-size: 0.875em;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  background-color: rgba(45, 55, 72, 0.5);
}

.prose pre {
  background-color: #2d3748;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
  margin: 1.5em 0;
}





/* Optimize markdown content rendering */
.prose * {
  margin: 0;
  padding: 0;
}

.prose h1,
.prose h2,
.prose h3,
.prose h4 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.prose p,
.prose ul,
.prose ol {
  margin-bottom: 0.75em;
}

.message.generating {
  opacity: 1;
  animation: fadeIn 0.3s ease-out;
}

.message.generating .message-content {
  position: relative;
  background-color: transparent;
}

.message.user .message-content {
  margin-top: 19px;
  background-color: #1d314a;
  border-radius: 14px 4px 14px 14px;
  padding: 12px 18px;
}

.message.assistant .message-content {
  background-color: transparent;
  padding: 12px 18px;
  border-radius: 4px;
  font-size: 16px;
  max-width: calc(100% - 86px);
  margin-bottom: 10px;
  color: #f4efef;
  margin-top: -5px;

}




.message.generating .message-content::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, 
    rgba(52, 152, 219, 0.2) 0%,
    rgba(52, 152, 219, 0.8) 50%,
    rgba(52, 152, 219, 0.2) 100%
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
}



.markdown-content {
  transition: all 0.1s ease-out;
}

/* Optimize performance */
.message {
  will-change: transform, opacity;
  transform: translateZ(0);
}

.message-content {
  will-change: transform;
  transform: translateZ(0);
}

::selection {
  background-color: #024a85;
  color: #ffffff;
}

::-moz-selection {
  background-color: #0d78a6;
  color: #ffffff;
}


