/* CheckOut.css */

/* Enterprise-Grade Checkout Main Styles */


/* Main container styling */
.checkout-container {
  width: 100%;
  height: calc(100vh);
  background-color: #f7fafc;
  color: #1a202c;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  margin-top: 60px;
  position: relative;
}

.checkout-layout {
  display: flex;
  height: 100%;
}

.checkout-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #dfe8ef !important;
}

/* Input section styling */
.checkout-input-section {
  background-color: #ffffff;
  padding: 24px 28px;
  border-bottom: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.checkout-section-title {
  font-size: 14px;
  font-weight: 600;
  color: #4a5568;
  margin: 0 0 12px 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.checkout-input-row {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.checkout-input-row:last-child {
  margin-bottom: 0;
}

/* Input styling */
.barcode-input,
.store-select,
.price-input,
.quantity-input {
  padding: 12px 16px;
  border: 1px solid #bcc7d3;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.barcode-input:focus,
.store-select:focus,
.price-input:focus,
.quantity-input:focus {
  outline: none;
  border-color: #4299e1;
}

.barcode-input {
  flex: 2;
  height: 48px;
}

.store-select {
  flex: 1;
  height: 48px;
  appearance: menulist;
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.price-input,
.quantity-input {
  flex: 1;
  height: 44px;
}

/* Button styling */
.checkout-search-button,
.checkout-add-button {
  height: 44px;
  padding: 0 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.checkout-search-button {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
  color: white;
  min-width: 100px;
}

.checkout-add-button {
  background: linear-gradient(120deg, #2c5282 0%, #2a4365 100%);
  color: white;
  min-width: 80px;
}

.checkout-search-button:hover,
.checkout-add-button:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkout-search-button:hover {
  background: linear-gradient(120deg, #2b6cb0 0%, #2c5282 100%);
}

.checkout-add-button:hover {
  background: linear-gradient(120deg, #2a4365 0%, #1a365d 100%);
}

.checkout-search-button:disabled,
.checkout-add-button:disabled {
  background: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Message styling */
.checkout-error,
.checkout-success {
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.checkout-error {
  background-color: rgba(245, 101, 101, 0.08);
  color: #e53e3e;
  border-left: 4px solid #e53e3e;
}

.checkout-success {
  background-color: rgba(76, 153, 175, 0.1);
  color: #1091cc;
  border-left: 4px solid #1091cc;

}



/* Current item styling */
.current-item {
  background-color: #ebf8ff;
  padding: 14px 16px;
  border-radius: 8px;
  margin-top: 16px;
  border-left: 4px solid #4299e1;
}

.current-item p {
  margin: 5px 0;
  font-size: 14px;
  color: #2c5282;
}

.current-item strong {
  font-weight: 600;
  color: #2b6cb0;
}

/* Cart section styling */
.checkout-cart-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 28px 28px 28px;
  margin-top: 20px;
}

.checkout-cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.checkout-cart-title {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.checkout-cart-actions {
  display: flex;
  gap: 12px;
}

.checkout-summary {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* Cart table styling */
.cart-table {
  width: 100%;
  border-collapse: collapse;
  color: #2d3748;
}

.cart-table th {
  background-color: #f8fafc;
  padding: 14px 20px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #e2e8f0;
  color: #4a5568;
}



.cart-item-row {
  height: 60px;
  transition: background-color 0.2s ease;
}

.cart-item-row:hover {
  background-color: #f8fafc;
}

.cart-table th:last-child {
  width: 120px;
  text-align: center;
}

.cart-table th:last-child .checkout-inventory-button {
  float: right;
}

.no-items {
  text-align: center;
  color: #a0aec0;
  font-style: italic;
  padding: 32px !important;
  font-size: 15px;
}

/* Price display */
.price-display {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.currency-symbol {
  margin-right: 2px;
  color: #718096;
}

/* Quantity cell */
.quantity-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.quantity-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  line-height: 1;
  transition: all 0.2s ease;

  flex-shrink: 0; /* Prevent buttons from shrinking */

}

.quantity-btn.decrease {
  background-color: #e2e8f0;
  color: #4a5568;
}

.quantity-btn.increase {
  background-color: #3182ce;
  color: white;
}

.quantity-btn.decrease:hover {
  background-color: #cbd5e0;
}

.quantity-btn.increase:hover {
  background-color: #2b6cb0;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkout-quantity-display {
  width: 58px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid #b0bed0 !important;
  cursor: pointer;
  /* Ensure consistent box sizing */
  box-sizing: border-box;
}



.checkout-quantity-display::after {
  font-size: 10px;
  margin-left: 4px;
  opacity: 0;
}

.checkout-quantity-display:hover::after {
  opacity: 0.6;
}

.checkout-quantity-display-input-number{
  padding: 0 5px;
  text-align: center;}

/* Small quantity input field that appears when editing */
.quantity-input-small {
  width: 58px; /* Match exactly with checkout-quantity-display */
  height: 42px !important; /* Match exactly with checkout-quantity-display */
  padding: 0 4px;
  text-align: center;
  border: 1px solid #4299e1 !important;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  -moz-appearance: textfield; /* Remove spinner in Firefox */
  box-sizing: border-box; /* Ensure consistent box sizing */
  margin: 0; /* Remove default margin */
}

/* Remove spinner buttons from number input */
.quantity-input-small::-webkit-outer-spin-button,
.quantity-input-small::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Focus style for the input */
.quantity-input-small:focus {
  outline: none;
}

.checkout-quantity-display-readonly {
  padding: 4px 0;
  font-weight: 500;
}

/* Actions cell */
.actions-cell {
  text-align: center;
}


/* Inventory button */
.checkout-inventory-button {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
  color: white;
  padding: 8px 14px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
}

.checkout-inventory-button:hover {
  background: linear-gradient(120deg, #2b6cb0 0%, #2c5282 100%);
}

.checkout-inventory-button::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='21 8 21 21 3 21 3 8'%3E%3C/polyline%3E%3Crect x='1' y='3' width='22' height='5'%3E%3C/rect%3E%3Cline x1='10' y1='12' x2='14' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
  .checkout-input-row {
    flex-wrap: wrap;
  }
  
  .checkout-input-section {
    padding: 20px;
  }
  
  .checkout-cart-section {
    padding: 0 20px 20px 20px;
  }
}

@media (max-width: 768px) {
  .checkout-layout {
    flex-direction: column;
  }
  
  .barcode-input,
  .store-select {
    height: 44px;
  }
}

/* Action button styling */
.checkout-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* Sell, Cancel buttons */
.checkout-sell-button, .checkout-cancel-button, .checkout-return-button {
  flex: 1;
  padding: 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
}

.checkout-sell-button {
  background-color: #2d7ad2;
  color: white;
}

.checkout-cancel-button {
  background-color: #000000;
  color: white;
}

.checkout-sell-button:hover {
  background-color: #0e52a0;
}

.checkout-cancel-button:hover {
  background-color: #e30101;
}

.checkout-sell-button:disabled, .checkout-cancel-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Return mode styling */
.checkout-return-button {
  background-color: #4a90e2;
  color: white;
}

.checkout-return-button:hover {
  background-color: #357abd;
}

.checkout-return-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Toggle mode button */
.toggle-mode-button {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #2362b9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 20px;
}

.toggle-mode-button:hover {
  background-color: #144891;
}

/* Total display */
.total-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
}

.total-label {
  font-size: 20px;
  font-weight: bold;
}

.total-value {
  font-size: 20px;
  font-weight: bold;
  background-color: #d6dfe3;
  padding: 8px 15px;
  border-radius: 4px;
  min-width: 80px;
  text-align: right;
}




/* ======= Enhanced Inventory Modal Styles ======= */
.inventory-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(26, 32, 44, 0.75);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}


.inventory-modal {
  width: 90%;
  max-width: 1280px;
  height: 80%;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.inventory-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px;
  background: linear-gradient(120deg, #193363 0%, #204179 100%);
  border-bottom: 1px solid #e2e8f0;
}

.inventory-modal-header h2 {
  margin: 0;
  color: white;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.inventory-modal-header h2::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='21 8 21 21 3 21 3 8'%3E%3C/polyline%3E%3Crect x='1' y='3' width='22' height='5'%3E%3C/rect%3E%3Cline x1='10' y1='12' x2='14' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.close-modal-btn {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 1;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.close-modal-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.inventory-modal-content {
  flex: 1;
  padding: 24px 28px;
  overflow: auto; /* Change from overflow-y to overflow */
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px); /* Adjust based on your header height */
}

.inventory-controls {
  margin-bottom: 5px;
  flex-shrink: 0; /* Prevent controls from shrinking */

}

.inventory-search-filters {
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
}

.inventory-search-input {
  flex: 2;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23718096' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
  padding-left: 40px;
}

.inventory-search-input:focus {
  outline: none;
  border-color: #4299e1;
}

.filter-dropdown {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.filter-dropdown:focus {
  outline: none;
  border-color: #4299e1;
}

/* New filters and sort container styling */
.inventory-filters-sort-container {
  display: flex;
  gap: 24px;
}

/* Filters section styling */
.inventory-filters-section {
  display: flex;
  gap: 20px;
  flex: 2;
}

.filter-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-label {
  font-size: 12px;
  font-weight: 500;
  color: #4a5568;
}

/* Sort section styling */
.inventory-sort-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.sort-label {
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.sort-controls {
  display: flex;
  gap: 12px;
  align-items: center;
}

.sort-dropdown {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}


.checkout-sort-order-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  background: linear-gradient(120deg, #1655ae 0%, #2060ae 100%);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 120px;
}

.checkout-sort-order-button:hover {
  background: linear-gradient(120deg, #19498d 0%, #134078 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Inventory table container */
.inventory-table-container {
  flex: 1;
  overflow: auto; /* Change from 'hidden' to 'auto' to allow scrolling */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 150px); /* Limit height to ensure vertical scrolling */
  margin-top: 10px;
}

.inventory-table-wrapper {
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
}

.inventory-table {
  width: 100%;
  min-width: 1000px; /* Set a minimum width to force horizontal scrolling on small screens */
  border-collapse: collapse;
  font-size: 14px;

}

.inventory-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8fafc;
}

.inventory-table th {
  position: sticky;
  top: 0;
  background-color: #e0e9f1;
  padding: 16px 20px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #e2e8f0;
  color: #4a5568;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.inventory-table td {
  padding: 16px 20px;
  border-bottom: 1px solid #edf2f7da;
  color: #141a25;
  vertical-align: middle;
}

.inventory-table tr {
  transition: background-color 0.2s ease;
}

.inventory-table tr:hover {
  background-color: #f8fafc;
}
@media (max-height: 800px) {
  .inventory-modal {
    height: 90%;
  }
  
  .inventory-table-container {
    max-height: calc(100% - 130px);
  }
}

@media (max-width: 768px) {
  .inventory-modal-content {
    padding: 16px;
  }
  
  .inventory-controls {
    margin-bottom: 16px;
  }
}


/* Column widths */
.inventory-table th:nth-child(1),
.inventory-table td:nth-child(1) {
  width: 25%;
}

.inventory-table th:nth-child(2),
.inventory-table td:nth-child(2) {
  width: 15%;
}

.inventory-table th:nth-child(3),
.inventory-table td:nth-child(3) {
  width: 15%;
}

.inventory-table th:nth-child(4),
.inventory-table td:nth-child(4) {
  width: 10%;
}

.inventory-table th:nth-child(5),
.inventory-table td:nth-child(5) {
  width: 10%;
}

.inventory-table th:nth-child(6),
.inventory-table td:nth-child(6),
.inventory-table th:nth-child(7),
.inventory-table td:nth-child(7) {
  width: 10%;
}

.inventory-table th:last-child,
.inventory-table td:last-child {
  width: 15%;
  text-align: center;
}

/* Add to cart button styling */
.add-to-cart-btn {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
  color: white;
  border: none;
  padding: 8px 14px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.add-to-cart-btn:hover {
  background: linear-gradient(120deg, #2b6cb0 0%, #2c5282 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-to-cart-btn:disabled {
  background: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  opacity: 0.7;
}

/* Empty state styling */
.inventory-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: #a0aec0;
  font-size: 16px;
  text-align: center;
}

.inventory-empty-state::before {
  content: "";
  display: block;
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23CBD5E0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='21 8 21 21 3 21 3 8'%3E%3C/polyline%3E%3Crect x='1' y='3' width='22' height='5'%3E%3C/rect%3E%3Cline x1='10' y1='12' x2='14' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.5;
}

/* Pagination controls (optional) */
.inventory-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #f8fafc;
  border-top: 1px solid #e2e8f0;
}

.pagination-info {
  font-size: 14px;
  color: #718096;
}

.pagination-controls {
  display: flex;
  gap: 8px;
}

.pagination-button {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid #e2e8f0;
  color: #4a5568;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-button:hover {
  background-color: #edf2f7;
  border-color: #cbd5e0;
}

.pagination-button:disabled {
  color: #cbd5e0;
  cursor: not-allowed;
  background-color: #f7fafc;
}

/* Responsive adjustments */
@media (max-width: 1280px) {
  .inventory-filters-sort-container {
    flex-wrap: wrap;
  }
  
  .inventory-filters-section {
    flex: 1 1 100%;
  }
  
  .inventory-sort-section {
    flex: 1 1 100%;
    flex-direction: row;
    align-items: center;
  }
  
  .sort-label {
    margin-right: 12px;
  }
  
  .inventory-modal-content {
    padding: 20px;
  }
  
  .inventory-table th,
  .inventory-table td {
    padding: 14px 16px;
  }
}

@media (max-width: 768px) {
  .inventory-modal {
    width: 95%;
    height: 90%;
  }
  
  .inventory-search-filters {
    flex-direction: column;
  }
  
  .inventory-filters-section {
    flex-direction: column;
  }
  
  .inventory-sort-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sort-controls {
    width: 100%;
  }
  
  .inventory-table th:nth-child(2),
  .inventory-table td:nth-child(2),
  .inventory-table th:nth-child(6),
  .inventory-table td:nth-child(6),
  .inventory-table th:nth-child(7),
  .inventory-table td:nth-child(7) {
    display: none;
  }
}
/* Loading indicator */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  color: #fff;
}

/* Cart table header styling */
.cart-table {
  width: 100%;
  border-collapse: collapse;
  color: #333;
  font-family: Arial, sans-serif;
}

.cart-table th {
  background-color: #ffffff;
  color: #333;
  padding: 12px 15px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
}

/* Cart table body styling */
.cart-table td {
  padding: 14px 15px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  background-color: #ffffff;
}

/* Cart item row styling */
.cart-item-row {
  height: 60px; /* Fixed height for rows */
}

.cart-item-row:hover {
  background-color: #f5f5f5;
}

/* Cart table header styling for inventory button */
.cart-table th:last-child {
  width: 120px; /* Width for the last column */
  text-align: right;
}

.cart-table th:last-child .checkout-inventory-button {
  float: right;
}

/* Style for the actions cell that contains the remove button */
.actions-cell {
  text-align: center;
  width: 100px;
}

/* Improved remove button styling */
.remove-item {
  background-color: #d3d5d8;
  color: #000000;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  white-space: nowrap;
}

.remove-item:hover {
  background-color: #de0404;
  color: #ffffff;
}

/* Inventory button styling to match the reference image */
.checkout-inventory-button {
  background-color: #005cc5;
  color: white;
  padding: 7px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
}

.checkout-inventory-button:hover {
  background-color: #022a58;
}

/* Override some styles for mobile responsiveness */
@media (max-width: 768px) {
  .checkout-layout {
    flex-direction: column;
  }
  
  .checkout-sidebar {
    width: 100%;
    height: auto;
  }
  
  .checkout-main {
    height: auto;
  }
  
  .numpad-button {
    height: 40px;
    font-size: 16px;
  }
  
  .cart-table {
    font-size: 14px;
  }
  
  .quantity-btn {
    width: 20px;
    height: 20px;
  }
  
  .checkout-quantity-display {
    padding: 2px 8px;
  }
  
  .inventory-modal {
    width: 95%;
    height: 90%;
  }
  
  .inventory-search-filters {
    flex-direction: column;
  }
}

/* Special responsive behavior for filters and sort section */
@media (max-width: 900px) {
  .inventory-filters-sort-container {
    flex-direction: column;
  }
  
  .inventory-sort-section {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .sort-label {
    margin-right: 10px;
  }
}

/* Handle even smaller screens */
@media (max-width: 500px) {
  .inventory-filters-section {
    flex-direction: column;
  }
  
  .inventory-sort-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sort-controls {
    width: 100%;
  }


}



/* Enterprise-Grade Checkout Sidebar Styles */

.checkout-sidebar {
  width: 360px;
  background-color: #ffffff;
  color: #1a202c;
  padding: 0;
  box-shadow: -5px 0 25px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  border-radius: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Premium header styling */
.checkout-header {
  padding: 24px 28px;
  background: linear-gradient(120deg, #1e3c72 0%, #2a5298 100%);
  color: white;
  position: relative;
}

.checkout-title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkout-title-icon {
  font-size: 20px;
}

.checkout-subtitle {
  font-size: 13px;
  font-weight: 400;
  margin: 6px 0 0 0;
  color: rgba(255, 255, 255, 0.8);
}

/* Main content container with improved spacing */
.sidebar-content {
  padding: 23px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

/* Section title styling */
.section-title {
  font-size: 14px;
  font-weight: 600;
  color: #4a5568;
  margin: 0 0 12px 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Executive-style total display */
.total-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 0;
  margin-bottom: 5px;
  background-color: transparent;
  box-shadow: none;
}

.total-label {
  font-size: 16px;
  font-weight: 500;
  color: #4a5568;
}

.total-value {
  font-size: 28px;
  font-weight: 700;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  min-width: auto;
  text-align: right;
  box-shadow: none;
  color: #1a202c;
  border: none;
}

.total-currency-symbol {
  margin-right: 2px;
  font-weight: 600;
  font-size: 20px;
  color: inherit;
  opacity: 0.9;
}

/* Summary info displays premium details */
.transaction-summary {
  margin-top: -8px;
  display: flex;
  justify-content: flex-end;
}

.summary-info {
  font-size: 13px;
  color: #718096;
  display: flex;
  gap: 6px;
}

.summary-info-value {
  font-weight: 500;
  color: #4a5568;
}

/* Divider lines */
.sidebar-divider {
  width: 100%;
  height: 1px;
  background-color: #e2e8f0;
  margin: 0;
}

/* Main action buttons with premium styling */
.checkout-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 0;
}

/* Primary action button */
.checkout-primary-button {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background: linear-gradient(120deg, #1a365d 0%, #2a4365 100%);
  color: white;
  box-shadow: 0 4px 6px rgba(27, 60, 114, 0.15), 0 1px 3px rgba(0, 0, 0, 0.08);
  letter-spacing: 0.3px;
  position: relative;
  overflow: hidden;
}

.checkout-primary-button:hover {
  background: linear-gradient(120deg, #1e3a5f 0%, #2d4a73 100%);
  box-shadow: 0 7px 14px rgba(27, 60, 114, 0.2), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.checkout-primary-button:active {
  box-shadow: 0 3px 5px rgba(27, 60, 114, 0.15);
}

.checkout-primary-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.checkout-primary-button:hover::after {
  opacity: 1;
}

/* Secondary action buttons */
.checkout-secondary-buttons {
  display: flex;
  gap: 14px;
}

.checkout-secondary-button {
  flex: 1;
  padding: 14px 20px;
  font-size: 15px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background-color: #f7fafc;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.checkout-secondary-button:hover {
  background-color: #edf2f7;
  color: #2d3748;
  border-color: #cbd5e0;
}



.checkout-secondary-button.danger:hover {
  background-color: rgba(245, 101, 101, 0.1);
  color: #e53e3e;
  border-color: rgba(245, 101, 101, 0.3);
}

/* Disabled states for all buttons */
.checkout-primary-button:disabled,
.checkout-secondary-button:disabled {
  background: #edf2f7;
  color: #a0aec0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  border-color: #e2e8f0;
  opacity: 0.7;
}

.checkout-primary-button:disabled::after {
  display: none;
}

/* Premium numpad styling */
.numpad-section {
  padding-top: 8px;
}

.numpad {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.numpad-row {
  display: flex;
  gap: 8px;
}

/* Enterprise-level numpad buttons */
.numpad-button {
  flex: 1;
  height: 65px;
  background-color: #ffffff;
  color: #1a202c;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  position: relative;
  overflow: hidden;
}

.numpad-button:hover {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

.numpad-button:active {
  background-color: #edf2f7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03);
}

/* Special numpad buttons */
.numpad-button:nth-child(4) {
  background-color: #f7fafc;
  color: #4a5568;
  font-size: 16px;
}

.numpad-button:last-child {
  background: linear-gradient(120deg, #2d3748 0%, #1a202c 100%);
  color: white;
  font-weight: 600;
  border: none;
}

.numpad-button:last-child:hover {
  background: linear-gradient(120deg, #1a202c 0%, #171923 100%);
}

.numpad-button:last-child:active {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
}

/* Button highlight effect */
.numpad-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.numpad-button:active::after {
  opacity: 0.3;
  transform: scale(2);
  transition: opacity 0.1s ease, transform 0.1s ease;
}

/* Responsive adjustments */
@media (max-width: 1280px) {
  .checkout-sidebar {
    width: 340px;
  }
}

@media (max-width: 768px) {
  .checkout-sidebar {
    width: 100%;
    height: auto;
    border-left: none;
    border-top: 1px solid #e2e8f0;
  }
  
  .sidebar-content {
    padding: 20px;
    gap: 20px;
  }
  
  .numpad-button {
    height: 50px;
  }
}


