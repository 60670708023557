.admin-dashboard {
    padding: 84px 20px 20px 280px;
    min-height: 100vh;
    background-color: #f5f6fa;
    transition: padding-left 0.3s ease;
  }
  
  .sidebar-closed .admin-dashboard {
    padding-left: 90px;
  }
  
  /* Stats Grid */
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    margin-bottom: 24px;
  }
  
  .stat-card {
    background: white;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .stat-icon {
    padding: 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .stat-icon.orders {
    background-color: #eff6ff;
    color: #2563eb;
  }
  
  .stat-icon.customers {
    background-color: #f0fdf4;
    color: #16a34a;
  }
  
  .stat-icon.designs {
    background-color: #fef2f2;
    color: #dc2626;
  }
  
  .stat-icon.revenue {
    background-color: #f5f3ff;
    color: #7c3aed;
  }
  
  .stat-content h3 {
    color: #64748b;
    font-size: 0.875rem;
    margin-bottom: 4px;
  }
  
  .stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    margin: 0;
  }
  
  .stat-change {
    font-size: 0.75rem;
    color: #64748b;
  }
  
  .stat-change.positive {
    color: #16a34a;
  }
  
  /* Dashboard Grid */
  .dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
  }
  
  .dashboard-card {
    background: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .card-header h2 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1e293b;
    margin: 0;
  }
  
  .view-all {
    background: none;
    border: none;
    color: #2563eb;
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  /* Orders List */
  .orders-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .order-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    background: #f8fafc;
  }
  
  .order-info h4 {
    font-size: 0.875rem;
    color: #1e293b;
    margin: 0 0 4px 0;
  }
  
  .order-info p {
    font-size: 0.75rem;
    color: #64748b;
    margin: 0 0 4px 0;
  }
  
  .order-date {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.75rem;
    color: #94a3b8;
  }
  
  .order-status {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 0.75rem;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .order-status.completed {
    background-color: #f0fdf4;
    color: #16a34a;
  }
  
  .order-status.pending {
    background-color: #fff7ed;
    color: #ea580c;
  }
  
  .order-status.processing {
    background-color: #f0f9ff;
    color: #0284c7;
  }
  
  /* Designs List */
  .designs-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .design-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px;
    border-radius: 8px;
    background: #f8fafc;
  }
  
  .design-preview {
    width: 48px;
    height: 48px;
    background: #e2e8f0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .design-info h4 {
    font-size: 0.875rem;
    color: #1e293b;
    margin: 0 0 4px 0;
  }
  
  .design-info p {
    font-size: 0.75rem;
    color: #64748b;
    margin: 0 0 4px 0;
  }
  
  .design-rating {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.75rem;
    color: #64748b;
  }
  
  .edit-design {
    margin-left: auto;
    background: #eff6ff;
    color: #2563eb;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.75rem;
    cursor: pointer;
  }
  
  /* Quick Actions */
  .quick-actions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 12px;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    color: #1e293b;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .action-button:hover {
    background: #eff6ff;
    border-color: #2563eb;
    color: #2563eb;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .admin-dashboard {
      padding: 84px 16px 16px 16px;
    }
  
    .stats-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  
    .dashboard-grid {
      grid-template-columns: 1fr;
    }
  }