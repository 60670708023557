/* BrokerNavBar.css */
.broker-navbar {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 70px;
}

.broker-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
}

.broker-navbar-brand {
  display: flex;
  align-items: center;
}

.broker-navbar-brand a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.brand-logo {
  color: #1e40af;
  font-size: 22px;
  font-weight: 700;
  margin-right: 8px;
}

.brand-text {
  color: #555;
  font-size: 16px;
  font-weight: 500;
}

.navbar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.navbar-menu li {
  height: 100%;
  position: relative;
}

.navbar-menu li a {
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #555;
  text-decoration: none;
  font-weight: 500;
  height: 100%;
  transition: color 0.3s, background-color 0.3s;
}

.navbar-menu li a:hover {
  color: #1e40af;
  background-color: rgba(30, 64, 175, 0.05);
}

.navbar-menu li.active-nav-link a {
  color: #1e40af;
  border-bottom: 3px solid #1e40af;
}

.nav-icon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.notification-badge {
  background-color: #006eff;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  margin-left: 6px;
  padding: 0 4px;
}

.user-section {
  display: flex;
  align-items: center;
}

.user-profile-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 6px;
  position: relative;
}

.user-profile-dropdown:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #1e40af;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.user-name {
  font-weight: 500;
  margin-right: 8px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 4px;
  transition: transform 0.2s;
}

.dropdown-arrow.down {
  border-top: 5px solid #666;
  border-bottom: 0;
}

.dropdown-arrow.up {
  border-bottom: 5px solid #666;
  border-top: 0;
}

.user-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  min-width: 200px;
  z-index: 1001;
  margin-top: 8px;
  overflow: hidden;
}

.user-dropdown-menu a, 
.user-dropdown-menu button {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.user-dropdown-menu a:hover, 
.user-dropdown-menu button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.user-dropdown-menu i {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.dropdown-divider {
  height: 1px;
  background-color: #eee;
  margin: 4px 0;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

@media (max-width: 1024px) {
  .mobile-menu-button {
    display: block;
  }

  .navbar-menu {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: white;
    flex-direction: column;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, box-shadow 0.3s ease;
    box-shadow: none;
  }

  .navbar-menu.open {
    max-height: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  .navbar-menu li {
    height: auto;
  }

  .navbar-menu li a {
    padding: 15px 20px;
    justify-content: flex-start;
  }

  .navbar-menu li.active-nav-link a {
    border-bottom: none;
    border-left: 3px solid #1e40af;
  }
}