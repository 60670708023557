.saved-designs {
    padding: 2rem;
    background-color: #f5f5f5;
    min-height: calc(100vh - 64px);
  }
  
  .saved-designs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .saved-designs-header h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
  }
  
  .designs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }
  
  .design-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .design-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .design-preview {
    position: relative;
    padding-top: 75%; /* 4:3 Aspect Ratio */
    background-color: #f8f9fa;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .preview-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .design-preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: white;
  }
  
  .preview-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f6;
  }
  
  .design-info {
    padding: 1rem;
  }
  
  .design-info h3 {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1f2937;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .design-dimensions {
    font-size: 0.75rem;
    color: #6b7280;
    margin: 0.25rem 0 0;
  }
  
  .design-date {
    font-size: 0.75rem;
    color: #6b7280;
    margin: 0.25rem 0 0;
  }
  
  .design-actions {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 10;
  }
  
  .menu-button {
    background: rgba(255, 255, 255, 0.9);
    border: none;
    padding: 0.25rem;
    border-radius: 4px;
    cursor: pointer;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
  }
  
  .menu-button:hover {
    background: white;
  }
  
  .actions-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    z-index: 10;
    min-width: 150px;
  }
  
  .actions-menu-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    border: none;
    background: none;
    color: #374151;
    font-size: 0.875rem;
    text-align: left;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .actions-menu button:hover {
    background-color: #f3f4f6;
  }
  
  .error-message {
    background-color: #fee2e2;
    border: 1px solid #ef4444;
    color: #b91c1c;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: 4px;
  }
  
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 64px);
    color: #6b7280;
  }