/* NavBar.css */
.navbar {
  background-color: #ffffff;
  padding: 0.5px 46px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 32px;
  width: auto;
  margin-right: 25px;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 15px;
  list-style: none;
  margin: 20px;
  padding: 0;
}

.navbar-links li {
  position: relative;
}

.navbar-links li a {
  color: #1d1d1de1;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 2px;
}



.navbar-links li a:hover {
  color: #035cc9;
}

.navbar-links li a.active {
  color: #035cc9;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.finances-btn {
  background-color: #035cc9;
  color: #ffffffe1;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 100%;
  
}

.finances-btn:hover {
  background-color: #00316e;
  color: #ffffff;

}



.notification-count {
  background-color: #046ae7;
  color: white;
  border-radius: 50% !important;
  padding: 2px 6px !important;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -3px;
  right: 1px;
  display: flex;
  z-index: 1;
}

.notification-count-menu {
  background-color: #046ae7 !important;
  color: white;
  border-radius: 40% !important;
  padding: 2px 6px !important;
  font-size: 10px;
  justify-content: center;
  align-items: center;
}

.mobile-menu-toggle {
  color: #1d1d1de1 !important;
  background-color: transparent !important;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0 5px 9px !important;
  
  border-radius: 2px;
}




.mobile-menu-toggle:hover {
  color: #035cc9 !important;

}

.mobile-menu {
  display: none;
}


@media (max-width: 75vw) {
  .navbar {
    padding: 0 15px;
  }

  .navbar-container {
    padding: 0.5rem;
    height: 50px;
  }

  .navbar-links, 
  .navbar-right .finances-btn {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 44%;
    z-index: 9999;
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu.open {
    transform: translateX(0);
  }

  .mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }

  .mobile-menu-search {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    flex-grow: 1;
    margin-right: 1rem;
  }

  .mobile-menu-search input {
    border: none;
    background: none;
    flex-grow: 1;
    padding: 0.5rem;
    font-size: 16px;
  }

  .mobile-menu-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }

  .mobile-nav {
    flex-grow: 1;
    overflow-y: auto;
  }

  .mobile-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .mobile-nav li {
    border-bottom: 1px solid #e0e0e0;
  }

  .mobile-nav li:last-child {
    border-bottom: none;
  }

  .mobile-nav a,
  .mobile-nav .finances-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5px;
    color: #333;
    text-decoration: none;
    font-size: 16px;
  }

  .mobile-nav a:hover,
  .mobile-nav .finances-btn:hover {
    background-color: #f0f0f0;
  }

  .mobile-nav .finances-btn {
    width: 100%;
    text-align: left;
    background-color: transparent;
  }
}

.dax-ai-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: #0754c6;
  color: #fff;
  padding: 0 14px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  z-index: 1000;
  height: 48px;
  line-height: 10px; 
  font-size: 15px;
  transition: all 0.3s ease;
}

.dax-ai-button:hover {
  background-color: #022d62;
}

.dax-ai-button.enabled {
  background-color: #0754c6;
}

.dax-ai-button.enabled:hover {
  background-color: #022d62;
}

/* NavBar.css */


.main-content-sidebar {
  display: flex;
  flex: 1;
  padding: 30px;
}

.content-area {
  flex: 1;
  margin-left: 250px;
  padding: 20px;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 0.5px 46px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1;
  height: 60px;
}

.sidebar-main {
  position: fixed;
  left: 0;
  top: 61px;
  bottom: 0;
  width: 265px;
  background-color: #05152f !important;
  box-shadow: 2px 0 4px rgba(0,0,0,0.1);
  z-index: 900;
  padding: 20px 11px;
  overflow-y: auto;
}

.sidebar-main-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-main-links li a {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  color: #e7e7e7;
  text-decoration: none;
}

.sidebar-main-links li a:hover {
  color: #006bed;
}

.sidebar-main-links li a.active {
  color: #006bed;
}

.sidebar-main-links li a svg {
  min-width: 20px;
}

.sidebar-main-links li a .notification-count-menu {
  margin-left: auto;
  background-color: red ;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  min-width: 20px;
  text-align: center;
}

@media (max-width: 1200px) {
  .sidebar-main {
    display: none;
  }
  
  .content-area {
    margin-left: 0;
  }
}


.loading-container {
  position: fixed;
  top: 61px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #05152f;
  z-index: 1001;
  transition: opacity 0.3s ease;
}

/* .dashboard-container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.dashboard-container.loaded {
  opacity: 1;
  visibility: visible;
} */

.navbar {
  opacity: 1 !important;
  z-index: 1002;
}

.main-content-sidebar {
  transition: opacity 0.3s ease;
}

.main-content-sidebar .sidebar-main {
  position: fixed;
  left: 0;
  top: 61px;
  bottom: 0;
  width: 265px;
  background-color: #05152f !important;
  box-shadow: 2px 0 4px rgba(0,0,0,0.1);
  z-index: 900;
  padding: 20px 11px;
  overflow-y: auto;
}




.store-analytics-btn {
  position: absolute;
  bottom: 40px;
  background-color: #002868;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 100%;
  left: 0;
  right: 0;
}

.store-analytics-btn:hover {
  background-color: #090909;
  color: #ffffff;

}



.bottom-info-container
{
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  padding: 11px 24px;
  transition: background-color 0.2s ease;
  gap: 10px;

}

.user-info-container {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: #0a1f41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 24px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}



.sidebar-user-email {
  color: #d8e0ec;
  font-size: 14px;
}

.copied-notification {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 12px;
  animation: fadeIn 0.2s ease;
}



.user-role {
  background-color: #014cee;
  color: #ffffff;
  font-size: 12px;
  opacity: 0.8;
  padding: 2px 6px;
  border-radius: 9px;
  font-weight: 100;
}