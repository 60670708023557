/* AddStore.css */

.addstore-container {
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 8px;
    max-width: 600px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    height: 750px;
   }

   @media (max-width: 1200px) {
  .addstore-add-container {
  margin: 0 auto;
  }
  }
  @media (min-width: 1201px) {
  .addstore-add-container {
  margin: 0 auto;
  padding-left: 265px;
  }
  }
   
   .addstore-title {
    font-size: 2rem;
    color: #061992;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 600;
   }
   
   .addstore-error {
    color: #e74c3c;
    margin-bottom: 20px;
    text-align: center;
   }
   
   .addstore-form {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 20px;
   }
   
   .addstore-form-group {
    margin-bottom: 20px;
   }
   
   .addstore-form-group-input,
   .addstore-form-group select {
    width: 100%;
    padding: 12px;
    border: 1px solid #7ba5c4 !important;
    border-radius: 6px;
    background-color: #f9f9f9;
    color: #333333;
    font-size: 1rem;
    height: 48px;
    box-sizing: border-box;
   }
   
   .addstore-submit-btn {
    width: 100%;
    background-color: #201192;
    color: #ffffff;
    padding: 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
    height: 48px;
    margin-bottom: 10px;
   }
   
   .addstore-submit-btn:hover:not(:disabled) {
    background-color: #07145a;
   }
   
   .addstore-submit-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
   }