.confirmation-overlay-confirm-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirmation-dialog-confirm-page {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    height: 140px;
    overflow: hidden;
    border-top: 6px solid #0073ff !important;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .confirmation-content-confirm-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 16px;
  }
  
  .confirmation-message-confirm-page {
    color: #20232c;
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 25px 0;
    font-weight: 100;
    text-align: center;
    margin-top: 10px;
  }
  
  .confirmation-buttons-confirm-page {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  
  .cancel-btn-confirm,
  .confirm-btn-confirm {
    padding: 10px 18px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin: 0 10px;
    text-transform: uppercase;
  }
  
  .cancel-btn-confirm {
    background-color: #7e7e7e;
    color: #ffffff;
    border: none;
  }
  
  .confirm-btn-confirm {
    background-color: #046df7;
    color: #ffffff;
    border: none;
  }
  
  .cancel-btn-confirm:hover {
    background-color: #535354;
    color: #ffffff;

  }
  
  .confirm-btn-confirm:hover {
    background-color: #013982;
  }