/* InventoryManagement.css - Redesigned to match CheckOut.css style */


@media (max-width: 1200px) {
  .inventory-page-container {
    width: 100%;
    background-color: #ffffff !important;
    color: #1a202c;
    position: relative;
  }
  }
  @media (min-width: 1201px) {
    .inventory-page-container {
      background-color: #ffffff !important;
      color: #1a202c;
      position: relative;
      padding-left: 285px;
  }
  }


.inventory-page-layout {
  display: flex;
  height: 100%;
}

.inventory-page-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #ffffff;
}

/* Header section styling */
.inventory-page-header-section {
  background-color: #ffffff;
  padding: 24px 28px;
  border-bottom: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  max-width: 1360px;
}

.inventory-page-title {
  font-size: 22px;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 16px 0;
}

.inventory-page-search-filters {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.inventory-page-search-input {
  flex: 2;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23718096' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
  padding-left: 40px;
}

.inventory-page-search-input:focus {
  outline: none;
  border-color: #4299e1;
}

.filter-dropdown {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.filter-dropdown:focus {
  outline: none;
  border-color: #4299e1;
}

/* Filters and sort container */
.inventory-page-filters-sort-container {
  display: flex;
  gap: 16px;
  align-items: center;
}

/* Filters section styling */
.inventory-page-filters-section {
  display: flex;
  gap: 12px;
  flex: 2;
}

.filter-group {
  flex: 1;
}

/* Sort section styling */
.inventory-page-sort-section {
  flex: 1;
}

.sort-controls {
  display: flex;
  gap: 12px;
  align-items: center;
}

.sort-dropdown {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #2d3748;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.inventory-page-sort-order-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  background: linear-gradient(120deg, #2d3748 0%, #1a202c 100%);
  color: white;
  border: none !important;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 120px;
}

.inventory-page-sort-order-button:hover {
  background: linear-gradient(120deg, #1a202c 0%, #171923 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Toggle switch styling */
.inventory-page-filter-toggle {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  background-color: #e2e8f0;
  transition: .4s;
  border-radius: 34px;
  margin-right: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4299e1;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.toggle-label {
  font-size: 14px;
  color: #4a5568;
  font-weight: 500;
}

/* Inventory table container */
.inventory-page-table-container {
  flex: 1;
  overflow: auto;
  max-width: 1440px;
}

.inventory-page-table {
  width: 100%;
  border-collapse: collapse;
  color: #2d3748;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.208);
  overflow: hidden;

}



@media (max-width: 1400px) {
  .inventory-page-table th {
    background-color: #c9d2e1;
  padding: 5px 20px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #e2e8f0;
  color: #000413;
  padding-left: 50px !important;
  
  }
  }
  @media (min-width: 1401px) {
    .inventory-page-table th {
      background-color: #c9d2e1;
      padding: 10px 20px;
      text-align: left;
      font-weight: 600;
      font-size: 14px;
      border-bottom: 1px solid #e2e8f0;
      color: #000413;
      padding-left: 50px !important;
  }
  }



.inventory-page-table td {
  padding: 16px 20px;
  border-bottom: 1px solid #edf2f7;
  font-size: 14px;
  vertical-align: middle;
  padding-left: 50px !important;
  
}

.inventory-page-row {
  height: 60px;
  transition: background-color 0.2s ease;
}

.inventory-page-row:hover {
  background-color: #f8fafc;
}

.inventory-page-row.hovered {
  background-color: #f8fafc;
}

/* Product info styling */
.product-info {
  display: flex;
  flex-direction: column;
}

.product-name {
  font-weight: 500;
  margin-bottom: 4px;
}

.product-details {
  display: flex;
  gap: 8px;
  font-size: 12px;
  color: #718096;
}

/* Price cell styling */
.price-cell {
  font-weight: 500;
}

.price-display {
  display: flex;
  align-items: center;
}

.currency-symbol {
  margin-right: 2px;
  color: #718096;
}

/* Quantity badge styling */
.inventory-quantity-cell {
  text-align: center;
}

.quantity-badge {
  display: inline-block;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  min-width: 30px;
}

.quantity-badge.in-stock {
  background-color: #2d7ad2;
  color: white;
}

.quantity-badge.low-stock {
  background-color: #f29e03;
  color: white;
}

.quantity-badge.out-of-stock {
  background-color: #9e0d1e;
  color: white;
}

/* Actions cell styling */
.actions-cell {
  text-align: center;
}

.edit-button {
  background-color: #3182ce;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.edit-button:hover {
  background-color: #2b6cb0;
}

.edit-button:disabled {
  background-color: #cbd5e0;
  cursor: not-allowed;
}

/* No items message */
.no-items {
  text-align: center;
  color: #a0aec0;
  font-style: italic;
  padding: 32px !important;
  font-size: 15px;
}

/* Error and loading styling */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #4a5568;
  font-size: 18px;
  background-color: #f7fafc;
}

.checkout-error {
  padding: 12px 16px;
  border-radius: 8px;
  margin: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: rgba(245, 101, 101, 0.08);
  color: #e53e3e;
  border-left: 4px solid #e53e3e;
}

/* Responsive styles */
@media (max-width: 1280px) {
  .inventory-page-filters-sort-container {
    flex-wrap: wrap;
  }
  
  .inventory-page-filters-section {
    flex: 1 1 100%;
    margin-bottom: 12px;
  }
  
  .inventory-page-sort-section,
  .inventory-page-filter-toggle {
    flex: 1 1 45%;
  }
}

@media (max-width: 768px) {
  .inventory-page-header-section {
    padding: 20px;
  }
  
  .inventory-page-search-filters {
    flex-direction: column;
  }
  
  .inventory-page-filters-section {
    flex-direction: column;
    gap: 8px;
  }
  
  .inventory-page-table-container {
    padding: 0 20px 20px 20px;
  }
  
  .inventory-page-table {
    font-size: 13px;
  }
  
  .inventory-page-table th,
  .inventory-page-table td {
    padding: 12px 10px;
  }
  
  .product-details {
    display: none;
  }
}


/* Pagination component styling */
.inventory-pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  border-top: 1px solid #e2e8f0;
  background-color: #ffffff;
  gap: 12px;
}

.pagination-info {
  color: #718096;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #ffffff;
  color: #4a5568;
  cursor: pointer;
  
}

.pagination-button:hover:not(:disabled) {
  background-color: #f7fafc;
  color: #2d3748;
  border-color: #cbd5e0;
}

.pagination-button:disabled {
  cursor: not-allowed;
  border-color: #cbd5e0;

}

.pagination-pages {
  display: flex;
  gap: 4px;
}

.pagination-page-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  padding: 0 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #ffffff;
  color: #4a5568;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-page-button:hover {
  background-color: #f7fafc;
  color: #2d3748;
  border-color: #cbd5e0;
}

.pagination-page-button.active {
  background-color: #1c3c74;
  color: #ffffff;
  border-color: #1c3c74;
}

/* Additional pagination styles for mobile */
@media (max-width: 768px) {
  .inventory-pagination {
    gap: 16px;
    padding: 16px;
  }
  
  .pagination-controls {
    width: 100%;
    justify-content: center;
  }
}