.landing-page{
  background-color: #000054;
}

.landing-header {
  padding: 0px 75px;
  z-index: 1000; /* Ensure header is above other elements */
  background-color: #f7f9fa;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
  position: relative;
}

.header-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 60px;
}

.header-content-mobile {
  justify-content: space-between;
}

.header-content-desktop {
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.country-icon {
  height: 22px;
  width: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
}

.country-icon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.logo-daxtop img {
  height: 30px;
  width: auto;
  margin-right: 25px;
}

.landing-nav {
  display: flex;
}


.landing-nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.landing-nav li {
  position: relative;
  padding: 20px 15px;
}

.landing-nav a, .landing-nav span {
  text-decoration: none;
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.products-menu {
  position: relative;
}

.products-menu.active span {
  color: #0070f2;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 20px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  border-top: 3px solid #0070f2;
  width: 1000px;
  height: auto;
  min-height: 200px;
  align-items: flex-start;
}

.hr-design{
  width: 100%;
  border: 1px solid #e1e4e7 !important;
  margin-top: 10px;
  margin-bottom: 30px;
}


.landing-nav a:hover, .landing-nav span:hover, .landing-nav li.active span {
  color: #0070f2;
}

.dropdown-column {
  flex: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}
.dropdown-column h3 {
  font-size: 14px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px #333;
}

.dropdown-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Changed from center to flex-start */
  width: 100%; /* Added to ensure full width */
}

.dropdown-column li {
  font-size: 14px;
  color: #333;
  padding: 10px 0;
  cursor: pointer;
  text-align: left; /* Changed from center to left */
  width: 100%;
}

.dropdown-column li:hover {
  text-decoration: underline;
}


.landing-nav li:hover {
  opacity: 1;
  visibility: visible;
}

.login-button {
  background-color: rgb(215, 216, 226);
  color: #1d1d1de1;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
}

.login-button:hover {
  background-color: rgb(171, 173, 200);
}

.landing-main {
  padding: 0;
}

/* New Category Tabs */
.category-tabs {
  display: flex;
  justify-content: flex-start;
  padding: 0 75px;
  background-color: #f7f9fa;
  border-bottom: 1px solid #e1e4e7;
}

.tab-button {
  padding: 15px 20px;
  margin-right: 20px;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  font-size: 16px;
  font-weight: 500;
  color: #1d1d1d;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  color: #0070f2;
  border-bottom: 3px solid #0070f2;
}

.tab-button:hover {
  color: #0070f2;
}

.hero-section {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px - 53px); /* Adjusted for tabs height */
  overflow: hidden;
  max-height: 480px;
}

.hero-image {
  width: 100%;
  height: 100%;
}

.hero-image img {
  width: 2000px;
  height: 480px;
  object-fit: cover;
  object-position: center;
}

.hero-content {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 2rem;
  color: #c4c4c4;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  max-width: 40%;
  margin-right: 15px;
}

.hero-content h1 {
  font-size: 35px;
  margin-bottom: 16px;
  color: #fff;
}

.hero-content p {
  font-size: 19.2px;
  margin-bottom: 32px;
}

.cta-button {
  background-color: #0077B5;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 4px;
}

.cta-button:hover {
  background-color: #005f8c;
}

/* New Retail Panel */
.retail-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 75px;
  background-color: #f7f9fa;
  height: 400px;
  overflow: hidden;
}

.retail-content {
  width: 40%;
  padding-right: 40px;
}

.retail-content h2 {
  font-size: 48px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
  line-height: 1.1;
}

.retail-content p {
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  margin-bottom: 30px;
}

.retail-learn-more {
  background-color: transparent;
  color: #378cfb;
  border: 2px solid #3b88fd;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.retail-learn-more:hover {
  background-color: #3476f9;
  color: white;
}

.retail-image {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.retail-image img {
  max-width: 100%;
  max-height: 340px;
  object-fit: contain;
}

.contact-us-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0077B5;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.contact-us-popup {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1rem;
  border-radius: 4px;
  width: 300px;
  z-index: 1001;
}

.contact-us-popup h3 {
  margin-top: 0;
  color: #333;
}

.phone-number {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subtext {
  font-size: 0.9rem;
  color: #666;
}

.contact-options {
  margin-top: 1rem;
}

.option {
  margin-bottom: 1rem;
}

.option h4 {
  margin: 0 0 0.5rem 0;
  color: #333;
}

.option p {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}

.centered-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e9f4fb;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  text-align: center;
}

.welcome-message h1 {
  font-family: 'Aileron', sans-serif;
  font-size: 2rem;
  color: #e6e6e6;
  margin-bottom: 1rem;
  padding: 2px;
  font-weight: 500;
  background: linear-gradient(to bottom, #0080db, #00005f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 19px;
}

hr {
  width: 100%;
  border: 1px solid #00005f;
  margin-top: 10px;
  margin-bottom: 30px;
}

.welcome-message h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #555;
  margin-bottom: 3.5rem;
  font-weight: 100;
  margin-top: 2.5rem;
}

.welcome-message p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 1rem;
  box-sizing: border-box;
  margin-bottom: 25px;
  margin-top: 1px;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border: 1px solid #00005f;
  outline: none;
}

.input-field.error-active {
  border: 1px solid #00005f;
  border-radius: 4px;
  margin-bottom: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submit-button {
  background-color: #0070ac;
  color: #fff;
  border: none;
  padding: 0.85rem 7.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
}

.submit-button:hover {
  background-color: #00567d;
}

.error-message-1 {
  color: rgb(218, 0, 0) !important;
  font-size: 0.9rem;
  text-align: center;
}

.support-contact {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #0077B5;
}

.support-contact a {
  color: #0077B5;
  text-decoration: none;
}

.support-contact a:hover {
  text-decoration: underline;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.region-selector {
  background-color: #fff;
  min-width: 300px;
  max-height: 400px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  
}

.region-selector-overlay {
  position: absolute;
  top: 60px; /* Adjust based on your header height */
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  margin-right: 50px;
  
  
}

.country-search {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.region-container {
  display: flex;
  flex-direction: column;
}

.region-selector-p {
text-align: center;
}

.region-selector p {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;

}

.region {
  margin-bottom: 20px;
}

.region h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #666;
}

.region ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


.region li {
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.region li:hover {
  background-color: #f5f5f5;
}

.country-flag {
  width: 24px;
  height: 16px;
  margin-right: 10px;
  object-fit: cover;
}


.mobile-menu-toggle-l {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.mobile-menu-toggle-l svg {
  display: block;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;

}

.mobile-menu-l {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
}


.mobile-menu-container-l {
  position: fixed;
  top: 60px; /* Adjust based on your header height */
  right: 0;
  bottom: 0;
  background-color: #f7f9fa;
  z-index: 9999;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  width: 44%;
}

.mobile-menu-container-l.open {
  transform: translateY(0);
}

.mobile-menu-back-l {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
}

/* @keyframes slideIn {
 from {
 transform: translateX(100%);
 }
 to {
 transform: translateX(0);
 }
} */

.mobile-menu-header-l {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.mobile-menu-close-l {
  background: none;
  border: none;
  cursor: pointer;
}

.mobile-menu-title-l {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.mobile-menu-top-l {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}



.mobile-menu-search-l {
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  flex-grow: 1;
  margin-right: 1rem;
}

.mobile-menu-search-l input {
  border: none;
  background: none;
  flex-grow: 1;
  padding: 0.35rem;
  font-size: 16px;
  margin-left: 5px;
}


.mobile-nav-l {
  flex-grow: 1;
  overflow-y: auto;

}

.mobile-nav-l ul {
  list-style-type: none;
  margin-right: 5px;
  padding: 0rem 10px;
  
}

.mobile-nav-l li {
  padding-right: 150px;
  
}

.mobile-nav-l ul li {
  padding: 1.1rem 10px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.mobile-nav-l ul li:hover {
  background-color: #f0f0f0;
}

.mobile-nav-l ul li span {
  font-size: 16px;
  color: #333;
}

.mobile-menu-footer {
  padding: 1rem;
  background-color: #f5f5f5;
}

.explore-dax {
  width: 100%;
  padding: 0.75rem;
  background-color: #0070f2;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.arrow-design{
  padding-top: 15px;
  padding-left: 15px;
}

.arrow-design:hover{
  color: #0070f2;
}



.landing-page {
  position: relative;
}

.landing-header,
.landing-main {
  position: relative;
  
}

.mobile-header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .landing-nav,
  .header-right .login-button,
  .header-right .country-icon {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .landing-header {
    padding: 0 15px;
  }

  .header-content {
    height: 60px;
  }

  .logo-daxtop {
    margin-right: 1.5rem; /* Reduced margin to bring nav links closer */
  }

  .logo-daxtop img {
    height: 30px;
  }

  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
  }

  .category-tabs {
    padding: 0 15px;
  }

  .tab-button {
    padding: 10px 15px;
    margin-right: 10px;
    font-size: 14px;
  }

  .retail-panel {
    flex-direction: column;
    padding: 20px 15px;
    height: auto;
  }

  .retail-content {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .retail-content h2 {
    font-size: 32px;
  }

  .retail-image {
    width: 100%;
    justify-content: center;
  }

  .hero-content {
    max-width: 100%;
    padding: 20px;
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .cta-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  .centered-container {
    width: 95%;
    padding: 1.5rem;
  }

  .welcome-message h1 {
    font-size: 1.5rem;
  }

  .welcome-message h2 {
    font-size: 16px;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
  }

  .submit-button {
    padding: 0.75rem 5rem;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 1.5rem;
  }

  .hero-content p {
    font-size: 0.9rem;
  }

  .cta-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .welcome-message h1 {
    font-size: 1.2rem;
  }

  .welcome-message h2 {
    font-size: 14px;
  }

  .submit-button {
    padding: 0.65rem 4rem;
  }

  .retail-content h2 {
    font-size: 28px;
  }

  .retail-content p {
    font-size: 16px;
  }

  .tab-button {
    padding: 8px 12px;
    margin-right: 5px;
    font-size: 12px;
  }
}

.how-daxtop-works {
  background-color: #f6f8fd;
  padding: 50px 75px;
  border-top: 1px solid #e1e4e7;
}

.daxtop-works-title {
  font-size: 36px;
  font-weight: 700;
  color: #1a1a2b;
  margin-bottom: 40px;
  text-align: center;
}

.daxtop-works-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.daxtop-works-accordion {
  width: 70%;
  padding-right: 40px;
}

.daxtop-works-image {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.daxtop-works-image img {
  max-width: 100%;
  max-height: 550px;
  margin-right: 100px;  
}

.accordion-section {
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e4e7;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.accordion-header h3 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.accordion-content {
  padding: 0 0 20px 0;
}

.accordion-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin: 0;
}