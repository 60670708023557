.design-editor {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
  }
  
  .error-message {
    background-color: #fee2e2;
    border: 1px solid #ef4444;
    color: #b91c1c;
    padding: 0.75rem;
    margin: 0.5rem;
    border-radius: 4px;
  }
  
  .toolbar {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
    gap: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 20;
  }
  
  .back-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: none;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    color: #666;
    transition: all 0.2s ease;
  }
  
  .back-button:hover {
    background-color: #f5f5f5;
    border-color: #d0d0d0;
  }
  
  .title-input {
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 1rem;
    min-width: 200px;
  }
  
  .tool-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .tool-button {
    padding: 0.5rem;
    background: none;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    transition: all 0.2s ease;
  }
  
  .tool-button.active {
    background-color: #e3f2fd;
    border-color: #2196f3;
    color: #2196f3;
  }
  
  .tool-button:hover {
    background-color: #f5f5f5;
    border-color: #d0d0d0;
  }
  
  .zoom-level {
    font-size: 0.875rem;
    color: #666;
    margin: 0 0.5rem;
    min-width: 48px;
    text-align: center;
  }
  
  .save-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
    transition: background-color 0.2s ease;
    font-weight: 500;
  }
  
  .save-button:hover {
    background-color: #1976d2;
  }
  
  .save-button:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
  }
  
  .editor-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
  }
  
  .sidebar {
    width: 280px;
    background-color: white;
    border-radius: 0;
    padding: 1.5rem;
    overflow-y: auto;
    z-index: 15;
    height: calc(100vh - 64px);
    position: relative;
  }
  
  .left-sidebar {
    border-right: 1px solid #e0e0e0;
    background-color: white;
    position: sticky;
    left: 0;
    top: 0;
  }
  
  .right-sidebar {
    border-left: 1px solid #e0e0e0;
    position: sticky;
    right: 0;
    top: 0;
  }
  
  .sidebar-section {
    margin-bottom: 2rem;
    position: relative;
  }
  
  .sidebar-section h3 {
    font-size: 0.875rem;
    color: #444;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  
  .sidebar-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    padding: 0.875rem 1rem;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    color: #444;
    transition: all 0.2s ease;
    position: relative;
    z-index: 1;
  }
  
  .sidebar-button:hover {
    background-color: #f8f9fa;
    border-color: #d0d0d0;
    transform: translateY(-1px);
  }
  
  .sidebar-button:active {
    transform: translateY(0);
  }
  
  .sidebar-button svg {
    color: #666;
    transition: color 0.2s ease;
  }
  
  .sidebar-button:hover svg {
    color: #2196f3;
  }
  
  .canvas-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    overflow: auto;
    position: relative;
    padding: 2rem;
    min-width: 0;
  }
  
  .design-canvas {
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform-origin: center;
    border-radius: 4px;
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .loading-overlay p {
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #666;
  }
  
  .element-properties {
    padding: 1rem;
  }
  
  .property-group {
    margin-bottom: 1.25rem;
  }
  
  .property-group label {
    display: block;
    font-size: 0.875rem;
    color: #555;
    margin-bottom: 0.5rem;
  }
  
  .property-group input[type="text"],
  .property-group input[type="number"] {
    width: 100%;
    padding: 0.625rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  
  .property-group input[type="color"] {
    width: 100%;
    height: 40px;
    padding: 0;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  
  .size-inputs,
  .position-inputs {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .size-inputs input,
  .position-inputs input {
    width: 80px;
  }
  
  .delete-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    padding: 0.875rem;
    background-color: #fee2e2;
    color: #dc2626;
    border: 1px solid #fecaca;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 1.5rem;
  }
  
  .delete-button:hover {
    background-color: #fecaca;
  }
  
  .canvas-element {
    position: absolute;
    cursor: move;
    user-select: none;
  }
  
  .canvas-element.selected {
    outline: 2px solid #2196f3;
    outline-offset: 2px;
  }
  
  .resize-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #2196f3;
    border: 1px solid white;
    border-radius: 50%;
  }