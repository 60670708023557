.finances-page {
  min-height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.finances-navbar {
  background-color: #ffffff;
  padding: 0.5px 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.finances-content {
  flex: 1;
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 2rem;
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.finances-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.finances-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Rest of the CSS remains unchanged */

.finances-navbar .navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
}

.finances-navbar .navbar-left {
  display: flex;
  align-items: center;
}

.finances-navbar .navbar-logo {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.finances-navbar .navbar-logo img {
  height: 32px;
  width: auto;
  margin-right: 10px;
}

.finances-navbar .navbar-logo span {
  font-weight: bold;
  color: #035cc9;
}

.finances-navbar .navbar-links {
  display: flex;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.finances-navbar .navbar-links li a {
  color: #1d1d1de1;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 3px;
}

.finances-navbar .navbar-links li a:hover{
  color: #035cc9; 
}

.finances-navbar .navbar-links li a.active {
  color: #035cc9;
  background-color: rgba(3, 92, 201, 0.1);
}

.finances-navbar .navbar-actions {
  display: flex;
  align-items: center;
}

.finances-navbar .inventory-btn {
  background-color: rgb(186, 188, 208);
  color: #000000e1;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.finances-navbar .inventory-btn:hover {
  background-color: rgb(162, 164, 196);
}

.finances-content .h1-finances {
  color: #0b0258;
  text-align: center;
  margin-top: 2rem;
}

.mobile-menu-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.mobile-menu,
.mobile-menu-overlay,
.navbar,
.sidebar {
  z-index: 1000;
}

@media (max-width: 75vw) {
  .finances-navbar {
    padding: 0 15px;
  }

  .finances-navbar .navbar-container {
    padding: 0.5rem;
  }

  .pin-modal {
    width: 85%;
    max-width: 300px;
    padding: 20px;
    margin: 0;
  }

  .pin-modal .h2-finances {
    font-size: 20px;
  }

  .mobile-menu-toggle {
    display: block;
    z-index: 999;
  }
}

/* Additional styles for Accounting component */
.finances-content .accounting-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
}

.finances-content .react-tabs__tab-list {
  border-bottom: 2px solid #035cc9;
}

.finances-content .react-tabs__tab--selected {
  background-color: #035cc9;
  color: #ffffff;
}

.finances-content table {
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.finances-content th {
  background-color: #f0f2f5;
}

.pin-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.pin-modal {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 350px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.pin-modal .h2-finances {
  color: #2565cf;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.pin-input-container {
  margin-bottom: 20px;
}

.pin-input-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.pin-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 10001;
}

.pin-input-wrapper input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: white;
  position: relative;
  z-index: 10001;
}

.toggle-pin-visibility {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #2565cf;
  cursor: pointer;
  font-size: 14px;
  padding: 5px;
  z-index: 10002;
}

.pin-error {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.mobile-menu {
  z-index: 1500;
}


.unlock-button {
  width: 100%;
  padding: 12px;
  background-color: #0081f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  position: relative;
  z-index: 10001;
  margin-top: 15px;
}

.unlock-button:hover:not(:disabled) {
  background-color: #005ba1;
}

.unlock-button.disabled,
.unlock-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}


.finances-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.finances-page {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.finances-page.loading {
  opacity: 0;
}