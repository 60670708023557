.reports-container-main {
  padding: 2rem;
  min-height: calc(100vh - 64px);
}




  @media (max-width: 1200px) {
    .reports-page-container {
      background: white;
      border-radius: 12px;
      padding: 2rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      max-width: 1200px !important;
    }
    }
    @media (min-width: 1201px) {
    .reports-page-container {
      background: white;
      border-radius: 12px;
      padding: 2.5rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      max-width: 1200px !important;
      margin-left: 325px;

    }
    }



.reports-container-header {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reports-header {
  font-size: 1.875rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
  letter-spacing: -0.025em;
}

.reports-container-hr {
  border: none;
  border-top: 1px solid #e5e7eb;
  margin: 2rem 0;
}

.reports-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.page-size-control {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.page-size-control label {
  color: #4b5563;
  font-weight: 500;
}

.page-size-control select {
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  background-color: white;
  color: #1a1a1a;
  font-size: 0.875rem;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.25rem;
}

.download-btn {
  padding: 8px 16px;
  background-color: #0d78a6;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}


.download-btn:hover {
  background-color: #025376;
}





.reports-table-container {
  overflow-x: auto;
  margin-bottom: 2rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.reports-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 800px;
}

.reports-table th,
.reports-table td {
  padding: 0.875rem 1.25rem;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
}

.reports-table th {
  background: #f8fafc;
  font-weight: 600;
  color: #4b5563;
  white-space: nowrap;
  position: sticky;
  top: 0;
}

.reports-table tr:hover {
  background-color: #f8fafc;
}

.reports-table td {
  color: #1a1a1a;
  font-size: 0.875rem;
}

.reports-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.reports-pagination-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  background: white;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  color: #4b5563;
}

.reports-pagination-button:hover:not(:disabled) {
  background: #f8fafc;
  border-color: #2563eb;
  color: #2563eb;
}

.reports-pagination-button:disabled {
  background: #f8fafc;
  cursor: not-allowed;
  opacity: 0.5;
}

.reports-pagination-span {
  color: #4b5563;
  font-weight: 500;
}

.reports-pagination-input {
  width: 60px;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  text-align: center;
  font-size: 0.875rem;
}

.reports-pagination-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.error-message {
  text-align: center;
  color: #dc2626;
  padding: 1.5rem;
  background: #fef2f2;
  border-radius: 8px;
  border: 1px solid #fecaca;
}

@media (max-width: 768px) {
  .reports-container-main {
    padding: 1rem;
  }
  
  .reports-container {
    padding: 1.5rem;
  }
  
  .reports-controls {
    flex-direction: column;
    align-items: stretch;
  }
  
  .reports-pagination {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .reports-header {
    font-size: 1.5rem;
  }
}



/* Add these styles to your Reports.css file */

.reports-container-inner {
  width: 100%;
}

/* Adjust the table styling for when it's inside the profile container */
.profile-container .reports-table-container {
  overflow-x: auto;
  margin-bottom: 2rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  width: 100%;
}

.profile-container .reports-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 800px;
}

/* Make sure other styles from Reports.css are maintained */
.profile-container .reports-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.profile-container .reports-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 20px;
}