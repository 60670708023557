.messenger-create-order-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .messenger-create-order-content {
    background: white;
    width: 90%;
    max-width: 600px;
    min-height: 500px;
    max-height: 80vh;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: max-width 0.3s ease;
  }
  
  .messenger-create-order-content.has-items {
    max-width: 900px;
  }
  
  .messenger-create-order-header {
    padding: 16px;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
  }
  
  .messenger-create-order-header h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
  }
  
  .messenger-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    padding: 0 8px;
  }
  
  .messenger-create-order-body {
    display: flex;
    height: calc(100% - 57px);
  }
  
  .items-list-container {
    width: 300px;
    border-right: 1px solid #e9ecef;
    padding: 16px;
    background: #f8f9fa;
    display: none;
    position: relative;
    height: calc(100vh - 385px) !important;
    max-height: 500px;
    overflow-y: auto;
  }
  
  .has-items .items-list-container {
    display: block;
  }
  
  /* Custom scrollbar for items list */
  .items-list-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .items-list-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0px !important;
  }
  
  .items-list-container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 0px !important;
  }
  
  .items-list-container::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
  
  .form-container {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
  }
  
  .store-selection-messenger {
    margin-bottom: 16px;
  }
  
  .store-select-messenger {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 14px;
  }
  
  .delivery-date-section-messenger {
    background: #f8f9fa;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  .date-input-container-messenger {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  
  .form-row-messenger {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
  }
  
  .messenger-input,
  .messenger-select {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 14px;
  }
  
  .add-item-btn-messenger {
    width: 100%;
    padding: 8px;
    background: #0d1c80;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 16px;
  }
  
  .add-item-btn-messenger:hover {
    background: #0e23a9;
  }
  
  .item-card-messenger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: white;
    border-radius: 6px;
    margin-bottom: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .remove-item-btn-messenger {
    background: #d3d5d8;
    color: #000000;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .remove-item-btn-messenger:hover {
    background: #de0404;
    color: white;
  }
  
  .messenger-order-actions {
    padding: 16px;
    display: flex;
    gap: 12px;
    border-top: 1px solid #e9ecef;
    background: white;
  }
  
  .messenger-cancel-button,
  .messenger-submit-button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }
  
  .messenger-cancel-button {
    background: #ebeaea;
    color: #000000;
  }
  
  .messenger-submit-button {
    background: #0e0170;
    color: white;
  }


  .messenger-cancel-button:hover {
    background: #000000;
    color: white;
  }
  
  
  .messenger-submit-button:not(:disabled):hover {
    background: #000000;
  }
  
  .messenger-submit-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }



  /* ViewOrderModal.css */
.view-order-body {
    padding: 20px;
    overflow-y: auto;
    max-height: calc(80vh - 140px);
  }
  
  .view-order-table {
    width: 100%;
    border-collapse: collapse;
    background: white;
  }
  
  .view-order-table th,
  .view-order-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .view-order-table th {
    background: #f8f9fa;
    font-weight: 500;
    color: #333;
  }
  
  .view-order-table tr:hover {
    background-color: #f8f9fa;
  }
  
  /* OrderConfirmationDialog.css */
  .view-btn-confirm {
    padding: 10px 18px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin: 0 10px;
    text-transform: uppercase;
    background-color: #0a0056;
    color: #ffffff;
    border: none;
  }
  
  .view-btn-confirm:hover {
    background-color: #000033;
  }