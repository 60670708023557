
/* ================ Layout & Container Styles ================ */
.add-item-container {
  margin: 0 auto;

}



.inventory-container {
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #f7fafc;
  color: #1a202c;
  margin-top: 60px;
  position: relative;
}

.inventory-layout {
  display: flex;
  height: 100%;
}

.inventory-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #f7fafc;
}

/* ================ Header & Quick Actions ================ */
.inventory-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.quick-actions {
  display: flex;
  gap: 10px;
}

.quick-mode-toggle,
.duplicate-button,
.templates-button {
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
  background-color: #f7fafc;
  color: #4a5568;
}

.quick-mode-toggle:hover,
.duplicate-button:hover,
.templates-button:hover {
  background-color: #edf2f7;
  color: #2d3748;
  border-color: #cbd5e0;
}

.quick-mode-toggle.active {
  background-color: #4299e1;
  color: white;
  border-color: #3182ce;
}

/* ================ Templates Panel ================ */
.templates-panel {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.templates-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
}

.templates-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #2d3748;
}

.templates-save-button,
.templates-close-button {
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.templates-save-button {
  background-color: #4299e1;
  color: white;
  border: none;
}

.templates-save-button:hover {
  background-color: #3182ce;
}

.templates-close-button {
  background-color: #f7fafc;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  font-size: 18px;
  padding: 2px 10px;
  margin-left: 10px;
}

.templates-close-button:hover {
  background-color: #edf2f7;
  color: #2d3748;
}

.templates-list {
  max-height: 200px;
  overflow-y: auto;
  padding: 12px;
}

.template-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  background-color: #f8fafc;
  border-radius: 6px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.template-item:hover {
  background-color: #edf2f7;
}

.template-name {
  font-weight: 500;
  color: #2d3748;
}

.template-actions {
  display: flex;
  gap: 8px;
}

.template-load,
.template-delete {
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.template-load {
  background-color: #4299e1;
  color: white;
}

.template-load:hover {
  background-color: #3182ce;
}

.template-delete {
  background-color: rgba(245, 101, 101, 0.1);
  color: #e53e3e;
}

.template-delete:hover {
  background-color: rgba(245, 101, 101, 0.2);
}

.no-templates {
  color: #718096;
  font-style: italic;
  text-align: center;
  padding: 20px 0;
}

/* ================ Section Titles ================ */
.inventory-section-title {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

/* ================ Messages (Error & Success) ================ */
.inventory-error,
.inventory-success {
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.inventory-error {
  background-color: rgba(245, 101, 101, 0.08);
  color: #e53e3e;
  border-left: 4px solid #e53e3e;
}

.inventory-success {
  background-color: rgba(76, 153, 175, 0.1);
  color: #1091cc;
  border-left: 4px solid #1091cc;
}

/* ================ Input Section ================ */
.inventory-input-section {
  background-color: #ffffff;
  padding: 34px 44px;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 20px;
}

/* ================ Form Styling ================ */
.inventory-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inventory-form-row {
  display: flex;
  gap: 16px;
}

.inventory-form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inventory-form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #29303e;
}

.inventory-form-group input,
.inventory-form-group select,
.inventory-form-group textarea {
  padding: 12px 14px;
  border: 1px solid #bcc7d3;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.2s ease;
  min-height: 44px;
}

/* Empty field validation styles */
.inventory-form-group input.empty-field,
.inventory-form-group select.empty-field,
.inventory-form-group textarea.empty-field {
  border-color: #d34001;
  background-color: rgba(211, 64, 1, 0.05);
}

.inventory-form-group input:focus,
.inventory-form-group select:focus,
.inventory-form-group textarea:focus {
  outline: none;
  border-color: #4299e1;
}

.inventory-form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.inventory-form-group input:disabled,
.inventory-form-group select:disabled,
.inventory-form-group textarea:disabled {
  background-color: #edf2f7;
  border-color: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
}

.inventory-form-group.full-width {
  flex-basis: 100%;
}

/* ================ Input with Suggestions ================ */
.input-with-suggestions {
  position: relative;
}

.quick-suggestions {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.suggestion-button {
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
  background-color: #f7fafc;
  color: #4a5568;
}

.suggestion-button:hover {
  background-color: #edf2f7;
  border-color: #cbd5e0;
  color: #2d3748;
}

/* ================ Quantity Buttons ================ */
.quantity-buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.quantity-btn {
  padding: 6px 12px;
  min-width: 36px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
  background-color: #f7fafc;
  color: #4a5568;
}

.quantity-btn:hover:not(:disabled) {
  background-color: #edf2f7;
  border-color: #cbd5e0;
  color: #2d3748;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* ================ Expiry Date Row ================ */
.expiry-date-row {
  align-items: flex-end;
}

/* ================ Checkbox Container ================ */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: auto;
  height: 44px;
  flex-basis: 160px;
  flex-grow: 0;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0;
}

/* ================ Form Actions ================ */
.inventory-form-actions {
  display: flex;
  gap: 16px;
  margin-top: 10px;
}

.inventory-submit-btn,
.inventory-cancel-btn {
  height: 44px;
  padding: 0 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.inventory-submit-btn {
  background: linear-gradient(120deg, #2c5282 0%, #1a365d 100%);
  color: white;
  border: none;
  flex: 1;
}

.inventory-submit-btn:hover:not(:disabled) {
  background: linear-gradient(120deg, #2b4970 0%, #182848 100%);
}

.inventory-submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.inventory-cancel-btn {
  background-color: #edf2f7;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  flex-basis: 120px;
  flex-shrink: 0;
}

.inventory-cancel-btn:hover {
  background-color: #e2e8f0;
  color: #2d3748;
}

/* ================ Items List Section ================ */
.inventory-list-section {
  flex: 1;
  padding: 0 28px 28px 28px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.inventory-list-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-wrap: wrap;
  gap: 16px;
}

/* ================ Filtering & Sorting Controls ================ */
.inventory-controls {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.inventory-search-filters {
  display: flex;
  gap: 12px;
}

.inventory-search-input {
  flex: 2;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.2s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23718096' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
  padding-left: 40px;
}

.inventory-search-input:focus {
  outline: none;
  border-color: #4299e1;
}

.filter-dropdown {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  background-color: #ffffff;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.filter-dropdown:focus {
  outline: none;
  border-color: #4299e1;
}

.inventory-sort-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sort-controls {
  display: flex;
  gap: 12px;
  align-items: center;
}

.sort-dropdown {
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  background-color: #ffffff;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
  min-width: 150px;
}

.inventory-sort-order-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  background: linear-gradient(120deg, #2d3748 0%, #1a202c 100%);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 120px;
}

.inventory-sort-order-button:hover {
  background: linear-gradient(120deg, #1a202c 0%, #171923 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* ================ Table Styling ================ */
.inventory-table-container {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow: auto;
}

.inventory-table {
  width: 100%;
  border-collapse: collapse;
  color: #2d3748;
}

.inventory-table th {
  background-color: #f8fafc;
  padding: 14px 20px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #e2e8f0;
  color: #4a5568;
  position: sticky;
  top: 0;
  z-index: 10;
}

.inventory-table td {
  padding: 16px 20px;
  border-bottom: 1px solid #edf2f7;
  font-size: 14px;
  vertical-align: middle;
}

.inventory-table tr:hover {
  background-color: #f8fafc;
}

/* ================ Status Badges ================ */
.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: inline-block;
}

.status-available {
  background-color: rgba(72, 187, 120, 0.1);
  color: #1091cc;
}

.status-low-stock {
  background-color: rgba(237, 137, 54, 0.1);
  color: #dd6b20;
}

.status-not-available {
  background-color: rgba(245, 101, 101, 0.1);
  color: #e53e3e;
}

.status-expired {
  background-color: rgba(160, 174, 192, 0.1);
  color: #718096;
}

/* ================ Action Buttons in Table ================ */
.actions-cell {
  white-space: nowrap;
  display: flex;
  gap: 6px;
}

.inventory-btn-edit,
.inventory-btn-duplicate,
.inventory-btn-delete {
  padding: 6px 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
  flex: 1;
  min-width: 60px;
}

.inventory-btn-edit {
  background-color: #4299e1;
  color: white;
}

.inventory-btn-edit:hover {
  background-color: #3182ce;
}

.inventory-btn-duplicate {
  background-color: #4c51bf;
  color: white;
}

.inventory-btn-duplicate:hover {
  background-color: #434190;
}

.inventory-btn-delete {
  background-color: rgba(245, 101, 101, 0.1);
  color: #e53e3e;
}

.inventory-btn-delete:hover {
  background-color: rgba(245, 101, 101, 0.2);
}

/* ================ Sidebar Styling ================ */
.inventory-sidebar {
  width: 360px;
  background-color: #ffffff;
  padding: 0;
  box-shadow: -5px 0 25px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.inventory-sidebar-header {
  padding: 24px 28px;
  background: linear-gradient(120deg, #1e3c72 0%, #2a5298 100%);
  color: white;
}

.inventory-title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.5px;
}

.inventory-subtitle {
  font-size: 14px;
  font-weight: 400;
  margin: 6px 0 0 0;
  color: rgba(255, 255, 255, 0.8);
}

.sidebar-content {
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* ================ Form Status Display ================ */
.form-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #f7fafc;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.form-status-label {
  font-size: 15px;
  font-weight: 500;
  color: #4a5568;
}

.form-status-value {
  font-size: 15px;
  font-weight: 600;
  color: #2d3748;
}

/* ================ Required Fields Summary ================ */
.required-fields-summary {
  font-size: 14px;
  color: #718096;
  padding: 0 16px;
}

.summary-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.add-item-summary-info-value {
  color: #2d3748;
  font-weight: 500;
}

/* ================ Divider Lines ================ */
.sidebar-divider {
  width: 100%;
  height: 1px;
  background-color: #e2e8f0;
  margin: 0;
  border: none;
}

/* ================ Numpad Section ================ */
.numpad-section {
  padding: 16px;
  background-color: #f8fafc;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.numpad-title {
  font-size: 16px;
  font-weight: 600;
  color: #4a5568;
  margin: 0 0 12px 0;
}

.active-input-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #edf2f7;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #4a5568;
}

.active-field-name {
  font-weight: 600;
  color: #2d3748;
}

.numpad {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.numpad-row {
  display: flex;
  gap: 8px;
}

.numpad-button {
  flex: 1;
  height: 48px;
  background-color: #ffffff;
  color: #2d3748;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.numpad-button:hover {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

.numpad-button:active {
  background-color: #edf2f7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03);
}

.numpad-button:nth-child(4) {
  background-color: #f7fafc;
  color: #4a5568;
}

.numpad-button:last-child {
  background: linear-gradient(120deg, #2d3748 0%, #1a202c 100%);
  color: white;
  font-weight: 600;
  border: none;
}

.numpad-button:last-child:hover {
  background: linear-gradient(120deg, #1a202c 0%, #171923 100%);
}

/* ================ Keyboard Shortcuts ================ */
.keyboard-shortcuts {
  padding: 16px;
  background-color: #f8fafc;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.shortcuts-title {
  font-size: 16px;
  font-weight: 600;
  color: #4a5568;
  margin: 0 0 12px 0;
}

.shortcuts-list {
  padding-left: 20px;
  margin: 0;
}

.shortcuts-list li {
  margin-bottom: 8px;
  font-size: 14px;
  color: #4a5568;
}

.shortcut-key {
  display: inline-block;
  padding: 2px 6px;
  background-color: #edf2f7;
  border-radius: 4px;
  color: #2d3748;
  font-weight: 600;
  font-size: 12px;
  margin-right: 6px;
  border: 1px solid #e2e8f0;
}

/* ================ Sidebar Action Buttons ================ */
.sidebar-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sidebar-action-button {
  padding: 14px 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.sidebar-action-button.primary {
  background: linear-gradient(120deg, #1e3c72 0%, #2a5298 100%);
  color: white;
  border: none;
  box-shadow: 0 4px 6px rgba(27, 60, 114, 0.15);
}

.sidebar-action-button.primary:hover:not(:disabled) {
  background: linear-gradient(120deg, #1a365d 0%, #2a4365 100%);
  box-shadow: 0 6px 8px rgba(27, 60, 114, 0.2);
}

.sidebar-action-button.primary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  box-shadow: none;
}

.sidebar-action-secondary-buttons {
  display: flex;
  gap: 12px;
}

.sidebar-action-button.secondary {
  background-color: #edf2f7;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  flex: 1;
}

.sidebar-action-button.secondary:hover {
  background-color: #e2e8f0;
  color: #2d3748;
}

.sidebar-action-button.secondary.active-toggle {
  background-color: #4299e1;
  color: white;
  border-color: #3182ce;

}



/* ================ Confirmation Dialog Styling ================ */

/* ================ Loading Indicator ================ */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  color: #4a5568;
}

/* ================ Register Item Button ================ */
.register-item-button {
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  background: linear-gradient(120deg, #1e3c72 0%, #2a5298 100%);
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
}

.register-item-button::before {
  content: "+";
  font-size: 16px;
  font-weight: bold;
}

.register-item-button:hover {
  background: linear-gradient(120deg, #1a345d 0%, #234781 100%);
}

.register-item-button:active {
  transform: translateY(0);
}

/* ================ Registry Info Section in Sidebar ================ */
.registry-info {
  padding: 16px;
  background-color: #f8fafc;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.registry-info h3 {
  font-size: 16px;
  font-weight: 600;
  color: #4a5568;
  margin: 0 0 12px 0;
}

.registry-description {
  font-size: 14px;
  color: #4a5568;
  margin-bottom: 12px;
  line-height: 1.5;
}

.registry-status {
  font-size: 14px;
  color: #4a5568;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.registry-loaded {
  color: #1091cc;
  font-weight: 500;
}

.registry-loading {
  color: #4299e1;
  font-weight: 500;
}

.registry-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #edf2f7;
  color: #4a5568;
  border-radius: 6px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  text-align: center;
}

.registry-link:hover {
  background-color: #e2e8f0;
  color: #2d3748;
}

/* ================ Template Status Section in Sidebar ================ */
.template-status {
  padding: 16px;
  background-color: #f8fafc;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
}

.template-status-label {
  font-size: 15px;
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 8px;
}

.template-status-value {
  font-size: 15px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 8px;
}

.template-status-value.found {
  background-color: rgba(66, 153, 225, 0.1);
  color: #1091cc;
}

.template-status-value.not-found {
  background-color: rgba(160, 174, 192, 0.1);
  color: #718096;
}

.template-expiry-info {
  font-size: 14px;
  color: #4a5568;
  background-color: rgba(66, 153, 225, 0.1);
  padding: 6px 12px;
  border-radius: 4px;
  margin-top: 8px;
}

/* ================ Input with Scanning State ================ */
input.scanning {
  background-color: rgba(66, 153, 225, 0.1);
  border-color: #4299e1;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.4);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(66, 153, 225, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(66, 153, 225, 0);
  }
}

/* ================ Responsive Styles ================ */
@media (max-width: 1280px) {
  .inventory-layout {
    flex-direction: column;
  }
  
  .inventory-sidebar {
    width: 100%;
    height: auto;
    box-shadow: 0 -5px 25px rgba(0, 0, 0, 0.07);
    order: -1;
  }
  
  .sidebar-content {
    padding: 16px;
  }
  
  .inventory-sidebar-header {
    padding: 16px;
  }
  
  .numpad-button {
    height: 40px;
  }
  
  .quick-actions {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .inventory-form-row {
    flex-direction: column;
    gap: 16px;
  }
  
  .inventory-search-filters {
    flex-direction: column;
  }
  
  .inventory-sort-section {
    width: 100%;
  }
  
  .sort-controls {
    width: 100%;
  }
  
  .sort-dropdown {
    flex: 1;
  }
  
  .inventory-input-section, 
  .inventory-list-section {
    padding: 16px;
  }
  
  .inventory-list-header {
    flex-direction: column;
    align-items: stretch;
  }
  
  .actions-cell {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .inventory-btn-edit,
  .inventory-btn-duplicate,
  .inventory-btn-delete {
    margin-right: 0;
    width: 100%;
    text-align: center;
  }
  
  .checkbox-container {
    flex-basis: auto;
  }
  
  .expiry-date-row {
    flex-direction: column;
  }
  
  .inventory-table th:nth-child(3),
  .inventory-table td:nth-child(3) {
    display: none;
  }
  
  .inventory-table th:nth-child(4),
  .inventory-table td:nth-child(4) {
    display: none;
  }
  
  .inventory-header-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  
  .quick-suggestions {
    flex-direction: column;
    gap: 6px;
  }
}


/* Add this to your CSS file after the existing media queries */
@media (max-width: 1400px) {
  .inventory-sidebar {
    display: none; /* Hide the sidebar completely */
  }
  
  .inventory-main {
    width: 100%; /* Ensure main content takes full width */
  }
  
  /* Adjust the container to account for missing sidebar */
  .inventory-container {
    margin-top: 60px;
  }
  
  /* Ensure layout still works without the sidebar */
  .inventory-layout {
    display: block; /* Change from flex to block */
  }
}


.take-out-input-row {
  padding: 14px;
  background-color: rgba(66, 153, 225, 0.05);
  border: 1px solid rgba(66, 153, 225, 0.2);
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.take-out-actions {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

/* Take Out Button */
.inventory-take-out-btn {
  height: 44px;
  padding: 0 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background: linear-gradient(120deg, #4299e1 0%, #3182ce 100%);
  color: white;
  border: none;
  min-width: 120px;
}

.inventory-take-out-btn:hover:not(:disabled) {
  background: linear-gradient(120deg, #3182ce 0%, #2b6cb0 100%);
}

.inventory-take-out-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Adjust spacing for three buttons */
.inventory-form-actions {
  flex-wrap: wrap;
  gap: 12px;
}

/* When three buttons are present, balance sizes */
.inventory-form-actions .inventory-submit-btn {
  flex: 2;
  min-width: 140px;
}

.inventory-form-actions .inventory-take-out-btn,
.inventory-form-actions .inventory-cancel-btn {
  flex: 1;
  min-width: 120px;
}

@media (max-width: 768px) {
  .inventory-form-actions {
    flex-direction: column;
  }
  
  .inventory-form-actions .inventory-submit-btn,
  .inventory-form-actions .inventory-take-out-btn,
  .inventory-form-actions .inventory-cancel-btn {
    width: 100%;
  }
  
  .take-out-input-row {
    flex-direction: column;
  }
  
  .take-out-actions {
    flex-direction: row;
  }
}