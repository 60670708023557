.mobile-menu-overlay-n {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
}

.mobile-menu-container-n {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  width: 80%;
  max-width: 400px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.mobile-menu-container-n.open {
  transform: translateX(0);
}

.mobile-menu-header-n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.mobile-menu-header-n h2 {
  margin: 0;
  font-size: 1.2rem;
}

.mobile-menu-close-n {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.mobile-menu-search-container-n {
  align-items: center;
  justify-content: center;
}

.mobile-menu-search-n {
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  flex-grow: 1;
  margin: 12px 1rem;
}

.mobile-menu-search-n input {
  border: none;
  background: none;
  flex-grow: 1;
  padding: 0.35rem;
  font-size: 16px;
  margin-left: 5px;
}

.mobile-nav-n {
  flex-grow: 1;
  overflow-y: auto;
}

.mobile-nav-n ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-nav-n li {
  border-bottom: 1px solid #e0e0e0;
}

.mobile-menu-item-n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.mobile-menu-item-n:hover {
  background-color: #f0f0f0;
  color: #035cc9;
}

.mobile-menu-item-n.active {
  color: #035cc9;
}

.notification-count-menu {
  background-color: #035cc9;
  color: white;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: auto;
  margin-right: 8px;
}

.mobile-menu-item-n.finances-item {
  background-color: #0055bd;
  color: #ffffff !important;
}

.mobile-menu-item-n.finances-item:hover {
  background-color: #01408e;
}
