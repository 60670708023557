/* Main container styles */
.accounting-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f5f5f5;
    overflow-x: hidden;
    min-width: 400px;

  }
  
  .h1-finances, .h2-finances, .h3-finances, .h4-finances {
    color: #2c3e50;
  }

  .accounting-form {
    max-width: 100% !important;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .accounting-form h2 {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .accounting-form h3 {
    color: #495057;
    border-bottom: 2px solid #ced4da;
    padding-bottom: 5px;
    margin-top: 20px;
  }
  
  
  .h1-finances {
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* Tab styles */
  .react-tabs__tab-list {
    border-bottom: 2px solid #2b65db !important;
    padding-left: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    position: relative;
    list-style: none;
    padding: 12px 15px;
    cursor: pointer;
  }
  
  .react-tabs__tab--selected {
    border-color: #3498db;
    color: #3498db;
  }
  
  .react-tabs__tab-panel {
    display: none;
    padding: 20px;
    border-top: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  /* Form styles */
  .company-info-form,
  .financial-data-form,
  .tax-rate-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group-a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .form-group-a label {
    display: block;
    margin-bottom: 5px;
    font-weight: 100;
    color: #555;
  }

  .employee-info-text {
    display: block;
    padding: 8px 0;
    color: #333;
  }
  
  
  .form-group-input-a {
    flex: 0 0 200px;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group-input-a:focus {
    outline: none;
    border: 1px solid #80bdff !important;
  }

  .form-group-input-a:disabled {
    flex: 0 0 200px;
    padding: 8px;
    border: 1px solid #ced4da !important ;
    border-radius: 4px;
    font-size: 16px;
    color: #7d7e7e !important;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 100;
  }
  
  /* input[type="text"],
  input[type="number"],
  input[type="date"],
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd !important;
    border-radius: 4px;
    font-size: 16px;
  } */

  .employee-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .search-bar {
    flex-grow: 1;
    margin-right: 20px;
  }
  
  .search-bar input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
  }

  
  .form-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
  }

  /* Employee table styles */
  .table-container {
    position: relative;
    overflow-x: auto;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  
  .employee-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .employee-table th,
  .employee-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .employee-table tr {
    position: relative;
    cursor: pointer;
  }
  
  .employee-table tr:hover td:not(.hover-message) {
    opacity: 0;
  }
  
  .employee-table .hover-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #012c59;
    color: white;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    padding: 0;
    margin: 0;
    border: none;
  }
  
  .employee-table tr:hover .hover-message {
    opacity: 1;
  }
    
 
  
  /* Ensure the hover message spans all columns */
.employee-table .hover-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* Remove any padding or margins that might affect sizing */
.employee-table td {
  padding: 12px;
  margin: 0;
}


.employee-table .view-profile {
  text-align: center;
  font-weight: 300;
  color: #007bff;
  padding: 12px;
  background-color: #f8f9fa;
}

.employee-details-form h2 {
  margin-bottom: 20px;
  color: #333;
}


  
.button-a,
.button-b {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.button-c{
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  min-width: 155px;
}

.button-c {
  background-color: #007bff;
  color: white;
}

.button-c:hover {
  background-color: #0056b3;
}

.button-a {
  background-color: #007bff;
  color: white;
}

.button-a:hover {
  background-color: #0056b3;
}

.button-b {
  background-color: #6c757d;
  color: white;
}

.button-b:hover {
  background-color: #5a6268;
}
  
  /* Financial reports styles */
  .financial-reports {
    margin-top: 30px;
  }
  
  .report {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .report h3 {
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  tr.total {
    font-weight: 100;
    background-color: #e0e0e0;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .react-tabs__tab {
      display: block;
      margin-bottom: 5px;
    }
  
    .react-tabs__tab-list {
      border-bottom: none;
    }
  
    .react-tabs__tab--selected {
      border: 1px solid #3498db;
    }
  
    .company-info-form,
    .financial-data-form,
    .tax-rate-form {
      padding: 10px;
    }
  
    input[type="text"],
    input[type="number"],
    input[type="date"],
    select {
      font-size: 14px;
    }
  }
  
  /* Loading and error styles */
  .loading, .error {
    text-align: center;
    padding: 20px;
    font-size: 18px;
  }
  
  .loading {
    color: #3498db;
  }
  
  .error {
    color: #e74c3c;
    background-color: #fad7d3;
    border: 1px solid #e74c3c;
    border-radius: 4px;
  }

  .pin-setup {
    max-width: 300px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .pin-setup h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #0056b3;
  }
  
  .pin-input-container {
    margin-bottom: 15px;
  }
  
  .pin-input-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .pin-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .pin-input-wrapper input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .toggle-pin-visibility {
    position: absolute;
    right: 10px;
    background: #fff;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;
  }
  
  .pin-error {
    color: #dc3545;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .submit-pin {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .submit-pin:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .submit-pin:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }